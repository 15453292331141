import React, { Component } from 'react';

//componentes
import PlanillaMC from './planillamc'

class Index extends Component {

    render(){
        return( 
            <div>
                <PlanillaMC />
            </div>
        );
    }

}
export default Index;