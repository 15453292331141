import React, { Component } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import axios from 'axios';
import {Link} from "react-router-dom";



//componentes
import './reportePago.css';

class ReportePago extends Component {
    constructor(props) {
        super(props);
        this.state = {
            identificacionp1:'',
            identificacionp2:'',
            tokenVerificar:'',
            tokenVerificarOn:false,
            token:'',
            tokenOn:false,
            opnivel:'MATERNAL',
            acudiente:'',
            alumno:'',
            referencia:'',
            banco:'',
            oppago:'TRANSFERENCIA',
            nombreper:'',
            fecha:'',
            monto:'',
            btnEnviarOn:false,
            meses:'',
            estatusOn:false,
        }
       
      
    }

    onChange(e) {
        //console.log(e.target.name)
        this.setState({
            [e.target.name]: e.target.value
             
        })
    }
    
    onChange3(e) {
        //console.log(e.target.value)
        this.setState({
            [e.target.name]: e.target.value             
        })
        this.calcularEdad(e.target.value)
    }

    handleClose = () => {
        this.setState({ mensajealerta: false })
    }

    limpiar =() =>{
        this.setState({
            identificacionp1:'',
            identificacionp2:'',
            tokenVerificar:'',
            tokenVerificarOn:false,
            token:'',
            tokenOn:false,
            opnivel:'MATERNAL',
            acudiente:'',
            alumno:'',
            referencia:'',
            banco:'',
            oppago:'TRANSFERENCIA',
            nombreper:'',
            fecha:'',
            monto:'',
            btnEnviarOn:false,
            meses:'',
            estatusOn:false,
        })
    }

    verificartoken = () => {
        if (this.state.tokenVerificar.length>0){
            if (this.state.tokenVerificar===this.state.token){
                console.log('Token verificado positivo')
                this.setState({
                    token: '',
                    tokenVerificarOn:true,

                });
            }else {
                console.log('Token verificado negativo')
            }
        }
    }

    registrarPago =()=>{
        if (this.state.referencia.length>0 && this.state.banco.length>0 && this.state.nombreper.length>0 && this.state.fecha.length>0 && this.state.monto.length>0 ) {

            let data = new FormData();
        
            data.append('cedulaAcudiente', this.state.identificacionp1); 
            data.append('acudiente', this.state.acudiente); 
            data.append('cedulaEstudiante', this.state.identificacionp2); 
            data.append('estudiante', this.state.alumno); 
            data.append('formaPago', this.state.oppago); 
            data.append('referencia', this.state.referencia); 
            data.append('banco', this.state.banco);
            data.append('nombreper', this.state.nombreper); 
            data.append('fecha', this.state.fecha);
            data.append('monto', this.state.monto); 
            data.append('nivel', this.state.opnivel); 
            data.append('mesapagar', this.state.meses); 
            data.append('boton', 'registrarPago');
            let config = {
                headers: {
                  'Content-Type': 'text/html'}
            };
            const xurl = 'https://institutobilinguelasnaciones.com/backend/reportarPago.php';
            axios.post(xurl, data, config)

                .then(res => {
                    if (res.data.variable1) {
                        this.setState({
                            btnEnviarOn:true
                        });
                    } else {
                        this.setState({
                            btnEnviarOn:false
                        });

                    }
                })
        }

    }
    

    buscarCedula=()=>{
        
        if (this.state.identificacionp1.length>0 && this.state.identificacionp2.length>0) {

            console.log('cedula '+this.state.identificacionp1)
            let data = new FormData();
            data.append('cedulaRepresentante', this.state.identificacionp1); 
            data.append('cedulaEstudiante', this.state.identificacionp2); 
            data.append('nivel', this.state.opnivel); 
            data.append('boton', 'buscarCedula');
            let config = {
                headers: {
                  'Content-Type': 'text/html'}
            };
            const xurl = 'https://institutobilinguelasnaciones.com/backend/reportarPago.php';
            axios.post(xurl, data, config)

                .then(res => {
                    if (res.data.variable1) {
                        this.setState({
                            tokenOn:res.data.variable1,
                            token: res.data.variable2,
                            acudiente:res.data.variable4,
                            alumno:res.data.variable5,
                        });
                    } else {
                        this.setState({
                            tokenOn:false,
                            token:'',
                            estatusOn: true,
                        });

                    }
                })
        }
    }

    render(){
        console.log(this.state.token)
        return(
            <div className="principal-pago row">
                <div className="contenedor-pago col-12">
                    <div className="titulo-pago ">
                        <p>MENU PARA REGISTRAR PAGOS DESDE EL PORTAL DE INTERNET. </p>
                    </div>
                    {!this.state.tokenVerificarOn &&
                      !this.state.estatusOn &&

                        <div className="contenedor-titulo row">
                            <div className="titulo-pago col-12">
                                <p>DEBE INGRESAR LA CEDULA O PASAPORTE DEL REPRESENTANTE Y DEL ESTUDIANTE Y ELEGIR EL NIVEL. </p>
                            </div>
                            <div className="contenedor-cedula row">
                                    <label className="labelcedulac">Inserte Cedula/Pasaporte Representante.</label>   
                                    <div className="contenedorcedula1 col-4">
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="identificacionp1"
                                            id="identificacionp1"
                                            autoComplete="off"
                                            placeholder="Identificacion Representante."
                                            maxLength="30"
                                            readOnly={this.state.tokenOn ??"readonly"}
                                            required
                                            onChange={this.onChange.bind(this)}
                                            value={this.state.identificacionp1}
                                        />  
                                    </div>                               
                            </div>
                            <div className="contenedor-cedula row">
                                    <label className="labelcedulac">Inserte Cedula/Pasaporte del Estudiante.</label>   
                                    <div className="contenedorcedula2 col-4">
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="identificacionp2"
                                            id="identificacionp2"
                                            autoComplete="off"
                                            placeholder="Identificacion Estudiante."
                                            maxLength="30"
                                            readOnly={this.state.tokenOn ??"readonly"}
                                            required
                                            onChange={this.onChange.bind(this)}
                                            value={this.state.identificacionp2}
                                        />  
                                    </div>                               
                            </div>
                            <div className="cont-radio col-10">
                                <label className="labelcedulac1">Indique el nivel del Estudiante.</label>   
                                <label>
                                    <input
                                        className='opnivel1' 
                                        type="radio" 
                                        value="MATERNAL"
                                        name="opnivel" 
                                        checked={this.state.opnivel === 'MATERNAL'}
                                        onChange={this.onChange.bind(this)}
                                    />
                                    Maternal
                                </label>
                                <label>
                                    <input 
                                        className='opnivel2' 
                                        type="radio" 
                                        value="PRIMARIA"
                                        name="opnivel" 
                                        checked={this.state.opnivel === 'PRIMARIA'}
                                        onChange={this.onChange.bind(this)}
                                    />
                                    Primaria
                                </label>
                            </div>
                            {!this.state.tokenOn &&
                                <div className="contenedorcedula1 col-12">
                                    <button className="botonm" onClick={this.buscarCedula.bind(this)}>Consultar</button>
                                </div>
                            }
                        </div>
                    }
                    {!this.state.tokenVerificarOn &&
                      !this.state.estatusOn &&
                        this.state.tokenOn &&
                            <div className="contenedor-titulo row">
                                <div className="titulo-pago col-12">
                                    <p>POR FAVOR VERIFIQUE SU CORREO SE LE HA ENVIADO UNA CLAVE DE VERIFICACION PARA SER INGRESADA EN EL CAMPO DE CLAVE. </p>
                                </div>
                                <div className="contenedor-cedula row">
                                        <label className="labelcedulac">Ingrese la Clave.</label>   
                                        <div className="contenedorcedula2 col-4">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="tokenVerificar"
                                                id="tokenVerificar"
                                                autoComplete="off"
                                                placeholder="Ingrese la clave enviada a el correo:"
                                                required
                                                onChange={this.onChange.bind(this)}
                                                value={this.state.tokenVerificar}
                                            />  
                                        </div>                               
                                        <button className="botonm" onClick={this.verificartoken.bind(this)}>Verificar</button>

                                </div>
                            </div>
                    }
                    {this.state.tokenVerificarOn &&
                      !this.state.estatusOn &&
                        !this.state.btnEnviarOn &&
                            <div className="contenedor-titulo row">
                                <div className="titulo-pago col-12">
                                    <p>AHORA PUEDE REGISTRAR EL PAGO. </p>
                                </div>
                                <div className="contenedor-cedula col-6">
                                        <label className="labelcedulac">Acudiente.</label>   
                                    
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="acudiente"
                                                id="acudiente"
                                                autoComplete="off"
                                                readOnly="readonly"
                                                onChange={this.onChange.bind(this)}
                                                value={this.state.acudiente}
                                            />  
                                                    
                                </div>
                                <div className="contenedor-cedula col-6">
                                        <label className="labelcedulac">Estudiante.</label>   
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="alumno"
                                                id="alumno"
                                                autoComplete="off"
                                                readOnly="readonly"
                                                onChange={this.onChange.bind(this)}
                                                value={this.state.alumno}
                                            />  
                                </div>   
                                <div className="cont-radio col-4">
                                    <label className="labelcedulac1">Forma de Pago.</label>   
                                    <label>
                                        <input
                                            className='opnivel1' 
                                            type="radio" 
                                            value="TRANSFERENCIA"
                                            name="oppago" 
                                            checked={this.state.oppago === 'TRANSFERENCIA'}
                                            onChange={this.onChange.bind(this)}
                                        />
                                        Transferencia.
                                    </label>
                                    <label>
                                        <input 
                                            className='opnivel2' 
                                            type="radio" 
                                            value="DEPOSITO"
                                            name="oppago" 
                                            checked={this.state.oppago === 'DEPOSITO'}
                                            onChange={this.onChange.bind(this)}
                                        />
                                        Deposito
                                    </label>
                                </div>
                                <div className="contenedor-cedula col-4">
                                        <label className="labelcedulac">Banco.</label>   
                                    
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="banco"
                                                id="banco"
                                                autoComplete="off"
                                                onChange={this.onChange.bind(this)}
                                                value={this.state.banco.toUpperCase()}
                                            />  
                                                    
                                </div>
                                <div className="contenedor-cedula col-4">
                                        <label className="labelcedulac">Referencia.</label>   
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="referencia"
                                                id="referencia"
                                                autoComplete="off"
                                                onChange={this.onChange.bind(this)}
                                                value={this.state.referencia}
                                            />  
                                </div>
                                <div className="contenedor-cedula col-2">
                                        <label className="labelcedulac">Monto.</label>   
                                    
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="monto"
                                                id="monto"
                                                autoComplete="off"
                                                onChange={this.onChange.bind(this)}
                                                value={this.state.monto}
                                            />  
                                                    
                                </div>
                                <div className="contenedor-cedula col-3">
                                    <label className="labelcedulac">Mes a Pagar.</label>   
                                    <select id="meses" name="meses" value={this.state.meses} onChange={this.onChange.bind(this)} >
                                        <option>--Mes a pagar--</option>
                                        <option value='1'>ENERO</option>
                                        <option value='2'>FEBRERO</option>
                                        <option value='3'>MARZO</option>
                                        <option value='4'>ABRIL</option>
                                        <option value='5'>MAYO</option>
                                        <option value='6'>JUNIO</option>
                                        <option value='7'>JULIO</option>
                                        <option value='8'>AGOSTO</option>
                                        <option value='9'>SEPTIEMBRE</option>
                                        <option value='10'>OCTUBRE</option>
                                        <option value='11'>NOVIEMBRE</option>
                                        <option value='12'>DICIEMBRE</option>
                                    </select>
                                </div>        

                                <div className="contenedor-cedula col-2">
                                        <label className="labelcedulac">Fecha.</label>   
                                            <input
                                                className="form-control"
                                                type="date"
                                                name="fecha"
                                                id="fecha"
                                                autoComplete="off"
                                                onChange={this.onChange.bind(this)}
                                                value={this.state.fecha}
                                            />  
                                </div>        
                                <div className="contenedor-cedula col-4">
                                        <label className="labelcedulac">Quien Realizó la Transferencia.</label>   
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="nombreper"
                                                id="nombreper"
                                                autoComplete="off"
                                                onChange={this.onChange.bind(this)}
                                                value={this.state.nombreper.toUpperCase()}
                                            />  
                                </div>
                                {!this.state.btnEnviarOn &&
                                <div className="contenedorcedula1 col-12">
                                    <button className="botonm" onClick={this.registrarPago.bind(this)}>Enviar Pago</button>
                                </div>        
                                }
                        </div>                 
                    }
                    {this.state.btnEnviarOn &&
                        <div className="titulo-pago col-12">
                            <p>EL PROCESO SE COMPLETO DE FORMA EXITOSA, LA INSTITUCIÓN SE COMUNICARÁ CON USTED GRACIAS. </p>
                            <button className="botonm" onClick={this.limpiar.bind(this)}>Continuar</button>
                        </div>
                    }    
                    {this.state.estatusOn &&
                      <div className="titulo-pago col-12">
                            <p>EL ESTATUS DE SU INSCRIPCION NO ESTA APROBADO, POR FAVOR COMUNIQUESE CON LA ADMINISTRACION DE LA INSTITUCIÓN. </p>
                            <button className="botonm" onClick={this.limpiar.bind(this)}>Continuar</button>
                        </div>
                    }
                </div>

            </div>
        )
    }
}export default ReportePago;