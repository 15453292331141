// Dependencias
import React, { Component } from 'react';
//import PropTypes from "prop-types"
import { withRouter } from "react-router"
// componentes

import AppRoutes from './routes'
import Header from './components/header';
//import SideBar from './SideBar/';

//css
import './App.css'
// import Chat from './Chat'
import Footer from './components/footer';
class AppPrincipal extends Component {
  //static propTypes = {
  //  match: PropTypes.object.isRequired,
  //  location: PropTypes.object.isRequired,
  //  history: PropTypes.object.isRequired
 // }
      
    render() {
        //console.log(this.props.location.pathname)
    
      return (
        <div className="conte">
          <div className="contenedor-principal1 row">
            <div className="cont-princi1 col-12">
               {/* <div className="cont-header"> */}
                    <Header />  
                {/* </div> */}
            </div>
          </div>      
                <div className="row">
                  <div className="cont-contenedor col-12">
                    {/* <div className="card"> */}
                        <AppRoutes />
                    {/* </div> */}
                  </div>
                </div>
                <div className="row">
                  <div className="cont-contenedor col-12">
                    
                     <Footer />
                  
                  </div>
                </div>

            {/* </div>        */}
          {/* <Footer copyright = "@copy; React 2018"/> */}
          {/* </div> */}
          {/* <Chat /> */}
        </div>
      );
    }
}
export default  withRouter(AppPrincipal);