//dependencias
import React from 'react';
import { Route, Switch } from 'react-router-dom';

//componentes


// import Nosotros from './components/Nosotros';
// import Productos from './components/Productos';
// import Pedido from './components/Pedido';
// import Cliente from './components/Cliente';
//import Leccion1 from './components/leccion1';
import Veranito from './components/planillaVeranito';
import SubirArchivos from './components/subirarchivos';
import ReportePago from './components/reportarPago';
import PlanillaMConsulta from './components/planillamc';
import PlanillaPConsulta from './components/planillapc';
import Contacto from './components/contacto';
import PlanillaM from './components/planillam';
import PlanillaP from './components/planillap';
import Nosotros from './components/nosotros';
import Fotos from './components/fotos';
import Maternal from './components/maternal';
import Preescolar from './components/preescolar';
import Primaria from './components/primaria';
import Premedia from './components/premedia';
import Superior from './components/superior';
import Home from './components/home';
import Pagina404 from './components/pagina404';

const AppRoutes = () => 
        <Switch>
            {/*<Route exact path="/registro" component={Registro} />
             <Route exact path="/ayuda" component={Ayuda} />
            <Route exact path="/nosotros" component={Nosotros} />
            <Route exact strict path="/casos" component={Casos} />
            <Route exact strict path="/estadistica" component={Estadisticas} />
            <Route exact strict path="/estupefacientes" component={Estupefacientes} />
            <Route exact strict path="/referencia" component={Referencia} />
            <Route exact strict path="/informe" component={Informe} />
            <Route exact strict path="/informeingreso" component={InformeIngreso} />
            <Route exact strict path="/constanciamedica" component={ConstanciaMedica} />
            <Route exact strict path="/reposomedico" component={ReposoMedico} />
            <Route exact strict path="/citas" component={CitasDia} />
            <Route exact strict path="/espera" component={Espera} />
            <Route exact strict path="/medicamento" component={Medicamento} />
            <Route exact strict path="/login" component={Login} />
            <Route exact strict path="/" component={Inicio} />*/}
            <Route exact strict path="/planillaV" component={Veranito} /> 
            <Route exact strict path="/archivosUp" component={SubirArchivos} /> 
            <Route exact strict path="/reportepago" component={ReportePago} /> 
            <Route exact strict path="/planillamc" component={PlanillaMConsulta} /> 
            <Route exact strict path="/planillapc" component={PlanillaPConsulta} /> 
            <Route exact strict path="/contacto" component={Contacto} /> 
            <Route exact strict path="/planillam" component={PlanillaM} /> 
            <Route exact strict path="/planillap" component={PlanillaP} /> 
            <Route exact strict path="/nosotros" component={Nosotros} /> 
            <Route exact strict path="/fotos" component={Fotos} /> 
            <Route exact strict path="/superior" component={Superior} />
            <Route exact strict path="/premedia" component={Premedia} />
            <Route exact strict path="/primaria" component={Primaria} />
            <Route exact strict path="/preescolar" component={Preescolar} />
            <Route exact strict path="/maternal" component={Maternal} />
            <Route exact strict path="/" component={Home} />             
            <Route component={Pagina404} />
        </Switch>   
export default AppRoutes;