import React, { Component } from 'react';
//import axios from 'axios';
import {Link} from "react-router-dom";



//componentes
import Footer from '../footer';
import './preescolar.css';

class Preescolar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            botonOn:true,
            mensajealerta:false,
        }
       
      
    }

    onChange(e) {
        //console.log(e.target.name)
        this.setState({
            [e.target.name]: e.target.value
             
        })
    }

    handleClose = () => {
        this.setState({ mensajealerta: false })
    }


    render(){
     
        return(
            <div className="container-fluid">
                <div className="cont-servi-principal row">
                    {/* <div className="cont-servi col-12">
                        <div className="container row"> */}
                    <div className="cont-imagen1pe col-7">
                        <div className="imagen1pe">
                            <img className="card-img-top" id="img1pe" src="/imgpreescolar/foto1.jpeg" alt="Card image cap"/>
                        </div>
                        <div className="imagen2pe">
                            <img className="card-img-top" id="img2pe" src="/imgpreescolar/foto2.jpeg" alt="Card image cap"/>
                        </div>
                        <div className="imagen3pe">
                            <img className="card-img-top" id="img3pe" src="/imgpreescolar/foto3.jpeg" alt="Card image cap"/>
                        </div>
                        <div className="imagen4pe">
                            <img className="card-img-top" id="img4pe" src="/imgpreescolar/letraA.png" alt="Card image cap"/>
                        </div>
                        <div className="imagen5pe">
                            <img className="card-img-top" id="img5pe" src="/imgpreescolar/letraB.png" alt="Card image cap"/>
                        </div>
                        <div className="imagen6pe">
                            <img className="card-img-top" id="img6pe" src="/imgpreescolar/letraC.png" alt="Card image cap"/>
                        </div>
                        <div className="imagen8pe">
                            <img className="card-img-top" id="img8pe" src="/imgpreescolar/tapenaranja.png" alt="Card image cap"/>
                        </div>
                        <div className="imagen9pe">
                            <img className="card-img-top" id="img9pe" src="/imgpreescolar/taperosado.png" alt="Card image cap"/>
                        </div>
                        <div className="imagen10pe">
                            <img className="card-img-top" id="img10pe" src="/imgpreescolar/tapeazul.png" alt="Card image cap"/>
                        </div>
                    </div>
                    <div className="eslogan1pe col-4">
                        <p className="titulope">Pre-escolar</p>
                        <div className="cuerpo-textope">
                            <p className="cuerpotexto1pe">Pre-escolar es la etapa más importante en el desarrollo de los niños, debido a que el siguiente paso es primaria, fase en donde los niños ya saben leer, aumentan sus niveles de disciplina, se convierten más independientes y se van desenvolviendo por sí mismos. Esta se divide en dos niveles:</p>
                            <p className="cuerpotexto2pe"><b id="cuerpotexto2pe">Pre-kinder:</b> Salón de niños de 4 años</p>
                            <p className="cuerpotexto3pe"><b id="cuerpotexto3pe">Kinder:</b> Salón de niños de 5 años</p>
                            <p className="cuerpotexto1pe">Para ofrecerle nuestra mejor atención y asegurarles una educación de calidad manejamos un máximo de 20 alumnos por salón. Cada salón tiene su respectiva teacher titular. Los servicios que ofrecemos para los niños de maternales son:</p>
                            <p className="cuerpotexto6pe"><b id="cuerpotexto2pe">Cámaras Internas.</b></p>
                            <p className="cuerpotexto7pe"><b id="cuerpotexto3pe">Clases pedagócicas.</b></p>
                            <p className="cuerpotexto8pe"><b id="cuerpotexto4pe">Clases de Inglés todos los días.</b></p>
                            <p className="cuerpotexto8pe"><b id="cuerpotexto4pe">Deporte.</b></p>
                            <p className="cuerpotexto9pe"><b id="cuerpotexto3pe">Parque.</b></p>
                            <p className="cuerpotexto10pe"><b id="cuerpotexto4pe">Plataforma de KidsBook.</b></p>
                            <p className="cuerpotexto10pe"><b id="cuerpotexto4pe">Salones con aires acondicionados.</b></p>
                            <p className="cuerpotexto10pe"><b id="cuerpotexto4pe">Horario de 7:00AM a 2:00PM.</b></p>
                            <p className="cuerpotexto10pe"><b id="cuerpotexto4pe">Horario extendido de 7:00AM a 6:00PM.</b></p>
                            <p className="cuerpotexto10pe"><b id="cuerpotexto4pe">(guardería o estudios dirigidas).</b></p>
                        </div>
                        <div className="links">
                            <a href="https://www.institutobilinguelasnaciones.com/fpdf/reportes/requisitosMaternal.php" target="__blank"><p className="cuerpotexto11"><b id="cuerpotexto5">Ir a Requisitos Pre escolar...</b></p> </a>
                           <a><Link className="linkp" to="/planillam"><p className="cuerpotexto11"><b className="planilla">Planilla.</b><b className="planilla1">Maternal-Pre escolar...</b></p></Link></a>
                        </div>

                        <div className="imagen7pe">
                            <img className="card-img-top" id="img7pe" src="/imgpreescolar/ninocolores.png" alt="Card image cap"/>
                        </div>
                    </div>
                </div>
                                        
                        {/* <Footer />  */}
                    {/* </div>         */}
                            
                {/* </div> */}
            </div>  
            
        )
    }
}export default Preescolar;    