import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { Link } from "react-router-dom";

//componentes
import Footer from "../footer";
import "./planillap.css";

class PlanillaP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      botonOn: true,
      botonNanaOn: false,
      botonChoferOn: false,
      botonOtroOn: false,
      mensajealerta: false,
      mensaje: "",
      nombre: "",
      apellido: "",
      identificacion: "",
      nombremadre: "",
      apellidomadre: "",
      identificacionmadre: "",
      ocupacionmadre: "",
      ltrabajomadre: "",
      telefonotrabajomadre: "",
      celularmadre: "",
      domiciliomadre: "",
      telecasamadre: "",
      correomadre: "",
      nacionalidadmadre: "",
      nombrepadre: "",
      apellidopadre: "",
      identificacionpadre: "",
      ocupacionpadre: "",
      ltrabajopadre: "",
      telefonotrabajopadre: "",
      celularpadre: "",
      domiciliopadre: "",
      telecasapadre: "",
      correopadre: "",
      nacionalidadpadre: "",
      fnacimiento: "",
      edad: "",
      telefonos: "",
      nacionalidad: "",
      lnacimiento: "",
      domicilio: "",
      personasviven: "",
      fechainicio: "",
      turno: "",
      transporte: "",
      personaencargada: "",
      telecontacto: "",
      madre: "",
      padre: "",
      nana: "",
      chofer: "",
      otro: "",
      autorizado1: "",
      autorizado2: "",
      autorizado3: "",
      autorizado4: "",
      autorizado5: "",
      celularperautori1: "",
      celularperautori2: "",
      celularperautori3: "",
      celularperautori4: "",
      celularperautori5: "",
      correoperautori1: "",
      correoperautori2: "",
      correoperautori3: "",
      nombrenoauto1: "",
      nombrenoauto2: "",
      nombrenoauto3: "",
      nombrenoauto4: "",
      nombrenoauto5: "",
      relacionnoauto1: "",
      relacionnoauto2: "",
      relacionnoauto3: "",
      nombreemergencia: "",
      relacionemergencia: "",
      tlfemergencia: "",
      antecedentes: "",
      tratamiento: "",
      tiempoembarazo: "",
      idiomamaterno: "",
      conductapositiva: "",
      conductanegativa: "",
      nombreescuela: "",
      rangotiempo: "",
      atendidopor: "",
      tiempocompartidomadre: "",
      tiempocompartidopadre: "",
      padeceenfermedad: "",
      hospitalizado: "",
      ocaciones: "",
      operadocausa: "",
      especifique: "",
      especifiquemedicamento: "",
      peso: "",
      talla: "",
      sangre: "",
      otroalergica: "",
      otroenfermedad: "",
      opantecedentes: "",
      btnemOn: false,
      tlfemergencia2: "",
      relacionemergencia2: "",
      nombreemergencia2: "",
      tlfemergencia3: "",
      relacionemergencia3: "",
      nombreemergencia3: "",
      nombrerecibo: "",
      cedularecibo: "",
      opcedula: "Cedula",
      opsexo: "MASCULINO",
      opcedulaM: "Cedula",
      opcedulaP: "Cedula",
      opnoautorizado: "NO",
      opantecedentes: "NO",
      optipoparto: "NORMAL",
      optratamiento: "NO",
      opexperiencia: "SI",
      ophospitalizado: "NO",
      opoperado: "NO",
      opcondicion: "NO",
      opmedicamento: "NO",
      opvacunacion: "COMPLETA",
      oprepresentante: "MADRE",
      opredessociales: "NO",
      opevangelio: "NO",
      icursos: [],
      igrado: [],
      cursos: "",
      grados: "",
      penicilina: "",
      mariscos: "",
      yodo: "",
      merthiolate: "",
      aspirina: "",
      citrico: "",
      laptosa: "",
      otroa: "",
      otroen: "",
      ninguna: "",
      convulsiones: "",
      asma: "",
      desmayo: "",
      diabetes: "",
      rubeola: "",
      papera: "",
      sarampion: "",
      varicela: "",
      tosferina: "",
      hepatitis: "",
      amebiasis: "",
      ningunae: "",
      btnAceptarOn: false,
      btnnoauto1On: "NO",
      btnnoauto2On: "NO",
      inicioOn: true,
    };
  }

  limpiar = () => {
    this.setState({
      botonOn: true,
      botonNanaOn: false,
      botonChoferOn: false,
      botonOtroOn: false,
      mensajealerta: false,
      nombre: "",
      apellido: "",
      identificacion: "",
      nombremadre: "",
      apellidomadre: "",
      identificacionmadre: "",
      ocupacionmadre: "",
      ltrabajomadre: "",
      telefonotrabajomadre: "",
      celularmadre: "",
      domiciliomadre: "",
      telecasamadre: "",
      correomadre: "",
      nacionalidadmadre: "",
      nombrepadre: "",
      apellidopadre: "",
      identificacionpadre: "",
      ocupacionpadre: "",
      ltrabajopadre: "",
      telefonotrabajopadre: "",
      celularpadre: "",
      domiciliopadre: "",
      telecasapadre: "",
      correopadre: "",
      nacionalidadpadre: "",
      fnacimiento: "",
      edad: "",
      telefonos: "",
      nacionalidad: "",
      lnacimiento: "",
      domicilio: "",
      personasviven: "",
      fechainicio: "",
      turno: "",
      transporte: "",
      personaencargada: "",
      telecontacto: "",
      madre: "",
      padre: "",
      nana: "",
      chofer: "",
      otro: "",
      autorizado1: "",
      autorizado2: "",
      autorizado3: "",
      autorizado4: "",
      autorizado5: "",
      celularperautori1: "",
      celularperautori2: "",
      celularperautori3: "",
      celularperautori4: "",
      celularperautori5: "",
      correoperautori1: "",
      correoperautori2: "",
      correoperautori3: "",
      nombrenoauto1: "",
      nombrenoauto2: "",
      nombrenoauto3: "",
      nombrenoauto4: "",
      nombrenoauto5: "",
      relacionnoauto1: "",
      relacionnoauto2: "",
      relacionnoauto3: "",
      nombreemergencia: "",
      relacionemergencia: "",
      tlfemergencia: "",
      antecedentes: "",
      tratamiento: "",
      tiempoembarazo: "",
      idiomamaterno: "",
      conductapositiva: "",
      conductanegativa: "",
      nombreescuela: "",
      rangotiempo: "",
      atendidopor: "",
      tiempocompartidomadre: "",
      tiempocompartidopadre: "",
      padeceenfermedad: "",
      hospitalizado: "",
      ocaciones: "",
      operadocausa: "",
      especifique: "",
      especifiquemedicamento: "",
      peso: "",
      talla: "",
      sangre: "",
      otroenfermedad: "",
      otroalergica: "",
      opantecedentes: "",
      btnemOn: false,
      tlfemergencia2: "",
      relacionemergencia2: "",
      nombreemergencia2: "",
      tlfemergencia3: "",
      relacionemergencia3: "",
      nombreemergencia3: "",
      nombrerecibo: "",
      cedularecibo: "",
      opcedula: "Cedula",
      opsexo: "MASCULINO",
      opcedulaM: "Cedula",
      opcedulaP: "Cedula",
      opnoautorizado: "NO",
      opantecedentes: "NO",
      optipoparto: "NORMAL",
      optratamiento: "NO",
      opexperiencia: "NO",
      ophospitalizado: "NO",
      opoperado: "NO",
      opcondicion: "NO",
      opmedicamento: "NO",
      opvacunacion: "COMPLETA",
      oprepresentante: "MADRE",
      opredessociales: "NO",
      opevangelio: "NO",
      // icursos: [],
      igrado: [],
      cursos: "",
      grados: "",
      penicilina: "",
      mariscos: "",
      yodo: "",
      merthiolate: "",
      aspirina: "",
      citrico: "",
      laptosa: "",
      otroa: "",
      otroen: "",
      ninguna: "",
      convulsiones: "",
      asma: "",
      desmayo: "",
      diabetes: "",
      rubeola: "",
      papera: "",
      sarampion: "",
      varicela: "",
      tosferina: "",
      hepatitis: "",
      amebiasis: "",
      ningunae: "",
      btnAceptarOn: false,
      btnnoauto1On: "NO",
      btnnoauto2On: "NO",
      mensaje: "",
    });
  };

  botonnoautorizado = () => {
    //console.log(this.state.btnemOn)
    if (this.state.btnnoauto1On === "SI") {
      this.setState({ btnnoauto1On: "NO" });
    } else {
      this.setState({ btnnoauto1On: "SI" });
    }
  };
  botonnoautorizado2 = () => {
    //console.log(this.state.btnemOn)
    if (this.state.btnnoauto2On === "SI") {
      this.setState({ btnnoauto2On: "NO" });
    } else {
      this.setState({ btnnoauto2On: "SI" });
    }
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  onChange4(e) {
    //console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.buscargrado(e.target.value);
  }

  onChange3(e) {
    //console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.calcularEdad(e.target.value);
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };
  tituloInicio = () => {
    if (this.state.inicioOn) {
      this.setState({
        inicioOn: false,
      });
    } else {
      this.setState({
        inicioOn: true,
      });
    }
  };
  onChange2(e) {
    // console.log(this.state.nana)
    // console.log(e.target.checked)
    if (e.target.checked) {
      if (e.target.name == "nana") {
        this.setState({
          botonNanaOn: !this.state.botonNanaOn,
        });
      }
      if (e.target.name == "chofer") {
        this.setState({
          botonChoferOn: !this.state.botonChoferOn,
        });
      }
      if (e.target.name == "otro") {
        this.setState({
          botonOtroOn: !this.state.botonOtroOn,
        });
      }
      this.setState({
        [e.target.name]: true,
      });
    } else {
      if (e.target.name == "nana") {
        this.setState({
          botonNanaOn: !this.state.botonNanaOn,
        });
      }
      if (e.target.name == "chofer") {
        this.setState({
          botonChoferOn: !this.state.botonChoferOn,
        });
      }
      if (e.target.name == "otro") {
        this.setState({
          botonOtroOn: !this.state.botonOtroOn,
        });
      }
      this.setState({
        [e.target.name]: false,
      });
    }
  }

  botonemergencia = () => {
    //console.log(this.state.btnemOn)
    if (this.state.btnemOn) {
      this.setState({ btnemOn: false });
    } else {
      this.setState({ btnemOn: true });
    }
  };
  botonemergencia2 = () => {
    //console.log(this.state.btnem2On)
    if (this.state.btnem2On) {
      this.setState({ btnem2On: false });
    } else {
      this.setState({ btnem2On: true });
    }
  };

  buscargrado = (e) => {
    //console.log(e);
    let data = new FormData();
    data.append("horario", e);
    data.append("boton", "buscarGrados");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/grado.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xgrado = res.data;
        this.setState({
          igrado: [],
          igrado: xgrado,
        });
      });
  };
  calcularEdad = (e) => {
    //console.log('paso '+e)
    var fecha = "";
    fecha = e;
    if (fecha.length > 0) {
      // Si la fecha es correcta, calculamos la edad
      var values = fecha.split("-");
      var dia = values[2];
      var mes = values[1];
      var ano = values[0];

      // cogemos los valores actuales
      var fecha_hoy = new Date();
      var ahora_ano = fecha_hoy.getYear();
      var ahora_mes = fecha_hoy.getMonth() + 1;
      var ahora_dia = fecha_hoy.getDate();

      // realizamos el calculo
      var edadf = ahora_ano + 1900 - ano;
      if (ahora_mes < mes) {
        edadf--;
      }
      if (mes == ahora_mes && ahora_dia < dia) {
        edadf--;
      }
      if (edadf > 1900) {
        edadf -= 1900;
      }

      this.setState({ edad: edadf });
    } else {
      this.setState({ edad: 0 });
    }
  };

  vistapreviaInscripcion = () => {
    let data = new FormData();
    data.append("nombreAlumno", this.state.nombre);
    data.append("apellidoAlumno", this.state.apellido);
    data.append("cedula", this.state.identificacion);
    data.append("cedulaLetra", this.state.opcedula);
    data.append("edad", this.state.edad);
    data.append("sexo", this.state.opsexo);
    data.append("lnacimiento", this.state.lnacimiento);
    data.append("fnacimiento", this.state.fnacimiento);
    data.append("nacionalidad", this.state.nacionalidad);
    data.append("telefono1", this.state.telefonos);
    data.append("domicilio1", this.state.domicilio);
    data.append("personaVive", this.state.personasviven);

    data.append("nombreMadre", this.state.nombremadre);
    data.append("apellidoMadre", this.state.apellidomadre);
    data.append("cedulaMadre", this.state.identificacionmadre);
    data.append("cedulaMadreLetra", this.state.opcedulaM);
    data.append("nacionalidadMadre", this.state.nacionalidadmadre);
    data.append("profesionMadre", this.state.ocupacionmadre);
    data.append("lugarTrabajoMadre", this.state.ltrabajomadre);
    data.append("telefonoTrabajoMadre", this.state.telefonotrabajomadre);
    data.append("celularMadre", this.state.celularmadre);
    data.append("domicilioMadre", this.state.domiciliomadre);
    data.append("telefonoCasaMadre", this.state.telecasamadre);
    data.append("correoMadre", this.state.correomadre);

    data.append("nombrePadre", this.state.nombrepadre);
    data.append("apellidoPadre", this.state.apellidopadre);
    data.append("cedulaPadre", this.state.identificacionpadre);
    data.append("cedulaPadreLetra", this.state.opcedulaP);
    data.append("nacionalidadPadre", this.state.nacionalidadpadre);
    data.append("profesionPadre", this.state.ocupacionpadre);
    data.append("lugarTrabajoPadre", this.state.ltrabajopadre);
    data.append("telefonoTrabajoPadre", this.state.telefonotrabajopadre);
    data.append("celularPadre", this.state.celularpadre);
    data.append("domicilioPadre", this.state.domiciliopadre);
    data.append("telefonoCasaPadre", this.state.telecasapadre);
    data.append("correoPadre", this.state.correopadre);

    data.append("fechaInicio", this.state.fechainicio);
    data.append("curso", this.state.cursos);
    data.append("transporte", this.state.transporte);
    data.append("personaEncargada", this.state.personaencargada);
    data.append("telefonoContacto", this.state.telecontacto);
    let auto = "";
    if (this.state.madre) auto = "MADRE, ";
    if (this.state.padre) auto += "PADRE, ";
    if (this.state.nana) auto += "NANA, ";
    if (this.state.chofer) auto += "CHOFER, ";
    if (this.state.otro) auto += "OTRO, ";
    data.append("autorizadoPor", auto);
    data.append("nombreNana", this.state.autorizado1);
    data.append("celularNana", this.state.celularperautori1);
    data.append("correoNana", this.state.correoperautori1);
    data.append("nombreChofer", this.state.autorizado2);
    data.append("celularChofer", this.state.celularperautori2);
    data.append("correoChofer", this.state.correoperautori2);
    data.append("nombreOtro", this.state.autorizado3);
    data.append("celularOtro", this.state.celularperautori3);
    data.append("correoOtro", this.state.correoperautori3);

    data.append("nombreNoAutorizado1", this.state.nombrenoauto1);
    data.append("relacion1", this.state.relacionnoauto1);
    data.append("nombreNoAutorizado2", this.state.nombrenoauto2);
    data.append("relacion2", this.state.relacionnoauto2);
    data.append("nombreNoAutorizado3", this.state.nombrenoauto3);
    data.append("relacion3", this.state.relacionnoauto3);

    data.append("nombreEmergencia1", this.state.nombreemergencia);
    data.append("relacionEmergencia1", this.state.relacionemergencia);
    data.append("telefonoEmergencia1", this.state.tlfemergencia);
    data.append("nombreEmergencia2", this.state.nombreemergencia2);
    data.append("relacionEmergencia2", this.state.relacionemergencia2);
    data.append("telefonoEmergencia2", this.state.tlfemergencia2);
    data.append("nombreEmergencia3", this.state.nombreemergencia3);
    data.append("relacionEmergencia3", this.state.relacionemergencia3);
    data.append("telefonoEmergencia3", this.state.tlfemergencia3);

    data.append("condicionEspecial", this.state.padeceenfermedad);
    data.append("estudianteHospitalizado", this.state.ophospitalizado);
    data.append("ocaciones", this.state.ocaciones);
    data.append("estudianteOperado", this.state.opoperado);
    data.append("causaOperacion", this.state.operadocausa);
    data.append("estudianteEnfermedad", this.state.opcondicion);
    data.append("especifiqueEnfermedad", this.state.especifique);
    data.append("estudianteMedicamento", this.state.opmedicamento);
    data.append("especifiqueMedicamento", this.state.especifiquemedicamento);
    data.append("vacunacion", this.state.opvacunacion);
    data.append("peso", this.state.peso);
    data.append("talla", this.state.talla);
    data.append("sangre", this.state.sangre);
    let alergico = "";
    if (this.state.ninguna) {
      alergico = "NO ES ALERGICO. ";
    } else {
      if (this.state.penicilina) alergico = "PENICILINA, ";
      if (this.state.mariscos) alergico += "MARISCOS, ";
      if (this.state.yodo) alergico += "YODO, ";
      if (this.state.merthiolate) alergico += "MERTHIOLATE, ";
      if (this.state.aspirina) alergico += "ASPIRINA, ";
      if (this.state.citrico) alergico += "CITRICO, ";
      if (this.state.laptosa) alergico += "LAPTOSA, ";
      if (this.state.otroa) alergico += this.state.otroalergica + "";
      alergico += ".";
    }
    data.append("estudianteAlergico", alergico);
    let enfermedad = "";
    if (this.state.ningunae) {
      enfermedad = "NINGUNA DE LAS ENFERMEDADES MENCIONADAS. ";
    } else {
      if (this.state.convulsiones) enfermedad = "CONVULSIONES, ";
      if (this.state.asma) enfermedad += "ASMA, ";
      if (this.state.desmayo) enfermedad += "DESMAYO, ";
      if (this.state.diabetes) enfermedad += "DIABETES, ";
      if (this.state.rubeola) enfermedad += "RUBEOLA, ";
      if (this.state.papera) enfermedad += "PAPERA, ";
      if (this.state.sarampion) enfermedad += "SARAMPION, ";
      if (this.state.varicela) enfermedad += "VARICELA, ";
      if (this.state.tosferina) enfermedad += "TOSFERINA, ";
      if (this.state.hepatitis) enfermedad += "HEPATITIS, ";
      if (this.state.amebiasis) enfermedad += "AMEBIASIS, ";
      if (this.state.otroen) enfermedad += this.state.otroenfermedad;
      enfermedad += ".";
    }
    data.append("estudianteHaSufrido", enfermedad);

    //data.append('recipe', JSON.stringify(this.state.irecipe));

    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/inscripcionPrimaria.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  vistapreviaAutorizacion = () => {
    let data = new FormData();
    data.append(
      "nombreEstudiante",
      this.state.nombre + " " + this.state.apellido
    );
    data.append("gradoid", this.state.cursos);
    data.append("fechaInicio", this.state.fechainicio);
    data.append("evangelio", this.state.opevangelio);
    data.append("redessociales", this.state.opredessociales);

    if (this.state.oprepresentante === "MADRE") {
      data.append("nombreRepresentante", this.state.nombremadre);
      data.append("apellidoRepresentante", this.state.apellidomadre);
      data.append("nacionalidad", this.state.nacionalidadmadre);
      data.append("cedulaRepresentante", this.state.identificacionmadre);
    }
    if (this.state.oprepresentante === "PADRE") {
      data.append("nombreRepresentante", this.state.nombrepadre);
      data.append("apellidoRepresentante", this.state.apellidopadre);
      data.append("nacionalidad", this.state.nacionalidadpadre);
      data.append("cedulaRepresentante", this.state.identificacionpadre);
    }
    //data.append('recipe', JSON.stringify(this.state.irecipe));

    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/autorizacion1.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  vistapreviaContrato = () => {
    let data = new FormData();
    data.append(
      "nombreEstudiante",
      this.state.nombre + " " + this.state.apellido
    );
    data.append("gradoid", this.state.cursos);
    data.append("grado", this.state.grados);

    data.append("fechaInicio", this.state.fechainicio);

    if (this.state.oprepresentante === "MADRE") {
      data.append("nombreRepresentante", this.state.nombremadre);
      data.append("apellidoRepresentante", this.state.apellidomadre);
      data.append("nacionalidad", this.state.nacionalidadmadre);
      data.append("cedulaRepresentante", this.state.identificacionmadre);
    }
    if (this.state.oprepresentante === "PADRE") {
      data.append("nombreRepresentante", this.state.nombrepadre);
      data.append("apellidoRepresentante", this.state.apellidopadre);
      data.append("nacionalidad", this.state.nacionalidadpadre);
      data.append("cedulaRepresentante", this.state.identificacionpadre);
    }
    //data.append('recipe', JSON.stringify(this.state.irecipe));

    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/contratoPrimaria.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  vistapreviaContratoCovid = () => {
    let data = new FormData();
    data.append(
      "nombreEstudiante",
      this.state.nombre + " " + this.state.apellido
    );
    data.append("gradoid", this.state.cursos);
    data.append("grado", this.state.grados);

    data.append("fechaInicio", this.state.fechainicio);

    if (this.state.oprepresentante === "MADRE") {
      data.append("nombreRepresentante", this.state.nombremadre);
      data.append("apellidoRepresentante", this.state.apellidomadre);
      data.append("nacionalidad", this.state.nacionalidadmadre);
      data.append("cedulaRepresentante", this.state.identificacionmadre);
    }
    if (this.state.oprepresentante === "PADRE") {
      data.append("nombreRepresentante", this.state.nombrepadre);
      data.append("apellidoRepresentante", this.state.apellidopadre);
      data.append("nacionalidad", this.state.nacionalidadpadre);
      data.append("cedulaRepresentante", this.state.identificacionpadre);
    }
    //data.append('recipe', JSON.stringify(this.state.irecipe));

    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/contratoPrimariaCovid.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  cursosHorarios = () => {
    axios
      .post(
        "https://institutobilinguelasnaciones.com/backend/maternal.php/?boton=consultarCursos"
      )
      .then((res) => {
        //console.log('pasooooo'+res.data)
        if (res.data) {
          let xcursos = res.data;
          this.setState({
            icursos: xcursos,
          });
        }
      });
  };

  vistapreviaPoliticas = () => {
    let data = new FormData();

    if (this.state.oprepresentante === "MADRE") {
      data.append("nombreRepresentante", this.state.nombremadre);
      data.append("apellidoRepresentante", this.state.apellidomadre);
    }
    if (this.state.oprepresentante === "PADRE") {
      data.append("nombreRepresentante", this.state.nombrepadre);
      data.append("apellidoRepresentante", this.state.apellidopadre);
    }
    //data.append('recipe', JSON.stringify(this.state.irecipe));

    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/politicasCumplirP.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  guardar = () => {
    let data = new FormData();
    data.append("nombreAlumno", this.state.nombre);
    data.append("apellidoAlumno", this.state.apellido);
    data.append("cedulaLetra", this.state.opcedula);
    data.append("cedula", this.state.identificacion);
    data.append("edad", this.state.edad);
    data.append("sexo", this.state.opsexo);
    data.append("lnacimiento", this.state.lnacimiento);
    data.append("fnacimiento", this.state.fnacimiento);
    data.append("nacionalidad", this.state.nacionalidad);
    data.append("telefono1", this.state.telefonos);
    data.append("domicilio1", this.state.domicilio);
    data.append("personaVive", this.state.personasviven);

    data.append("nombreMadre", this.state.nombremadre);
    data.append("apellidoMadre", this.state.apellidomadre);
    data.append("cedulaMadreLetra", this.state.opcedulaM);
    data.append("cedulaMadre", this.state.identificacionmadre);
    data.append("profesionMadre", this.state.ocupacionmadre);
    data.append("lugarTrabajoMadre", this.state.ltrabajomadre);
    data.append("telefonoTrabajoMadre", this.state.telefonotrabajomadre);
    data.append("celularMadre", this.state.celularmadre);
    data.append("domicilioMadre", this.state.domiciliomadre);
    data.append("telefonoCasaMadre", this.state.telecasamadre);
    data.append("correoMadre", this.state.correomadre);
    data.append("nacionalidadMadre", this.state.nacionalidadmadre);

    data.append("nombrePadre", this.state.nombrepadre);
    data.append("apellidoPadre", this.state.apellidopadre);
    data.append("cedulaPadreLetra", this.state.opcedulaP);
    data.append("cedulaPadre", this.state.identificacionpadre);
    data.append("profesionPadre", this.state.ocupacionpadre);
    data.append("lugarTrabajoPadre", this.state.ltrabajopadre);
    data.append("telefonoTrabajoPadre", this.state.telefonotrabajopadre);
    data.append("celularPadre", this.state.celularpadre);
    data.append("domicilioPadre", this.state.domiciliopadre);
    data.append("telefonoCasaPadre", this.state.telecasapadre);
    data.append("correoPadre", this.state.correopadre);
    data.append("nacionalidadPadre", this.state.nacionalidadpadre);

    data.append("fechaInicio", this.state.fechainicio);
    data.append("curso", this.state.cursos);
    data.append("grado", this.state.grados);
    data.append("transporte", this.state.transporte);
    data.append("personaEncargada", this.state.personaencargada);
    data.append("telefonoContacto", this.state.telecontacto);

    data.append("autorizadoPorMadre", this.state.madre);
    data.append("autorizadoPorPadre", this.state.padre);
    data.append("autorizadoPorNana", this.state.nana);
    data.append("autorizadoPorChofer", this.state.chofer);
    data.append("autorizadoPorOtro", this.state.otro);
    data.append("nombreNana", this.state.autorizado1);
    data.append("celularNana", this.state.celularperautori1);
    data.append("correoNana", this.state.correoperautori1);
    data.append("nombreChofer", this.state.autorizado2);
    data.append("celularChofer", this.state.celularperautori2);
    data.append("correoChofer", this.state.correoperautori3);
    data.append("nombreOtro", this.state.autorizado3);
    data.append("celularOtro", this.state.celularperautori3);
    data.append("correoOtro", this.state.correoperautori3);

    data.append("personaNoAutorizada", this.state.opnoautorizado);
    data.append("nombreNoAutorizado1", this.state.nombrenoauto1);
    data.append("relacion1", this.state.relacionnoauto1);
    data.append("nombreNoAutorizado2", this.state.nombrenoauto2);
    data.append("relacion2", this.state.relacionnoauto2);
    data.append("nombreNoAutorizado3", this.state.nombrenoauto3);
    data.append("relacion3", this.state.relacionnoauto3);

    data.append("nombreEmergencia1", this.state.nombreemergencia);
    data.append("relacionEmergencia1", this.state.relacionemergencia);
    data.append("telefonoEmergencia1", this.state.tlfemergencia);
    data.append("nombreEmergencia2", this.state.nombreemergencia2);
    data.append("relacionEmergencia2", this.state.relacionemergencia2);
    data.append("telefonoEmergencia2", this.state.tlfemergencia2);
    data.append("nombreEmergencia3", this.state.nombreemergencia3);
    data.append("relacionEmergencia3", this.state.relacionemergencia3);
    data.append("telefonoEmergencia3", this.state.tlfemergencia3);

    // estos datos van vacios pertenecen a maternal
    data.append("problemaGestacion", "");
    data.append("antecedentes", "");
    data.append("recibioTratamiento", "");
    data.append("tratamiento", "");
    data.append("duracionEmbarazo", "");
    data.append("tipoParto", "");
    data.append("idiomaMaterno", "");
    data.append("caracteristicaPositiva", "");
    data.append("caracteristicaNegativa", "");
    data.append("primeraExperiencia", this.state.opexperiencia);
    data.append("escuelaAnterior", this.state.nombreescuela);
    data.append("asistioRango", "");
    data.append("atendidoPor", "");
    data.append("horasMadre", "");
    data.append("horasPadre", "");

    // hasta aqui

    data.append("condicionEspecial", this.state.padeceenfermedad);
    data.append("estudianteHospitalizado", this.state.ophospitalizado);
    data.append("ocaciones", this.state.ocaciones);
    data.append("estudianteOperado", this.state.opoperado);
    data.append("causaOperacion", this.state.operadocausa);
    data.append("estudianteEnfermedad", this.state.opcondicion);
    data.append("especifiqueEnfermedad", this.state.especifique);
    data.append("estudianteMedicamento", this.state.opmedicamento);
    data.append("especifiqueMedicamento", this.state.especifiquemedicamento);
    data.append("vacunacion", this.state.opvacunacion);
    data.append("peso", this.state.peso);
    data.append("talla", this.state.talla);
    data.append("sangre", this.state.sangre);

    data.append("penicilina", this.state.penicilina);
    data.append("mariscos", this.state.mariscos);
    data.append("yodo", this.state.yodo);
    data.append("merthiolate", this.state.merthiolate);
    data.append("aspirina", this.state.aspirina);
    data.append("citrico", this.state.citrico);
    data.append("laptosa", this.state.laptosa);
    data.append("otroa", this.state.otroa);
    data.append("otroalergica", this.state.otroalergica);
    data.append("ninguna", this.state.ninguna);

    data.append("convulsiones", this.state.convulsiones);
    data.append("asma", this.state.asma);
    data.append("desmayo", this.state.desmayo);
    data.append("diabetes", this.state.diabetes);
    data.append("rubeola", this.state.rubeola);
    data.append("papera", this.state.papera);
    data.append("sarampiom", this.state.sarampion);
    data.append("varicela", this.state.varicela);
    data.append("tosferina", this.state.tosferina);
    data.append("hepatitis", this.state.hepatitis);
    data.append("amebiasis", this.state.amebiasis);
    data.append("otroen", this.state.otroen);
    data.append("otroenfermedad", this.state.otroenfermedad);
    data.append("ningunae", this.state.ningunae);
    data.append("evangelio", this.state.opevangelio);
    data.append("redessociales", this.state.opredessociales);
    data.append("representanteEstudiante", this.state.oprepresentante);
    data.append("anombreRecibo", this.state.nombrerecibo);
    data.append("cedulaRecibo", this.state.cedularecibo);
    data.append("estatus", "P");
    data.append("nivel", "P");
    data.append("boton", "guardar");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable1);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          this.setState({
            btnAceptarOn: true,
          });
        } else {
          this.setState({
            btnAceptarOn: false,
            mensajealerta: true,
            mensaje: res.data.variable2,
          });
        }
      });
  };

  componentDidMount() {
    this.cursosHorarios();
  }

  mensajeA = () => {
    if (this.state.mensajealerta) {
      this.setState({
        mensajealerta: false,
        mensaje: "",
      });
    } else {
      this.setState({
        mensajealerta: true,
      });
    }
  };

  render() {
    const HtmlTooltip = withStyles((theme) => ({
      tooltip: {
        backgroundColor: "#2bbbad",
        color: "#050505",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
      },
    }))(Tooltip);
    return (
      <div className="cont-planillam-principal row">
        <div className="cont-planillam col-12">
          <div className="container">
            <div className="eslogan">
              <p>Inscripción Primaria - Pre media - Media</p>
            </div>
            {this.state.inicioOn && (
              <div className="contenedor-titulo row">
                <div className="titulo-notificacion col-12">
                  <p>
                    ESTA PLANILLA DEBE SER LLENADA UNICAMENTE PARA FORMALIZAR LA
                    INSCRIPCIÓN, EN CASO CONTRARIO DEBERÁ IR A EL MENU
                    INFORMACIÓN Y ENVIAR UN MENSAJE POR EL FORMULARIO DE
                    CONTACTO.
                  </p>
                  <button
                    className="botonm"
                    onClick={this.tituloInicio.bind(this)}
                  >
                    Continuar
                  </button>
                </div>
              </div>
            )}
            {!this.state.inicioOn && !this.state.btnAceptarOn && (
              <div className="cont-datos-alumnos col-12">
                <div className="contenedor-titulo row">
                  <div className="titulo-estudiante col-12">
                    <p>DATOS DEL ESTUDIANTE</p>
                  </div>
                </div>

                <div className="fila1-datos-alumno row">
                  <div className="nombres col-6">
                    {/* <label className="opcion" >Nombres.</label> */}
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="nombre"
                      id="nombre"
                      autoComplete="off"
                      placeholder="Nombres:"
                      maxLength="30"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.nombre.toUpperCase()}
                    />
                  </div>

                  <div className="apellido col-6">
                    {/* <label className="opcion" >Apellidos.</label> */}
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="apellido"
                      id="apellido"
                      autoComplete="off"
                      placeholder="Apellidos:"
                      maxLength="30"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.apellido.toUpperCase()}
                    />
                    <span className="tooltip">
                      {" "}
                      Indique el Nombre del Estudiante{" "}
                    </span>
                  </div>
                </div>
                <div className="fila2-datos-alumno row">
                  <div className="radio-identificacion col-1">
                    <div className="radio">
                      <label>
                        <input
                          type="radio"
                          value="CEDULA"
                          name="opcedula"
                          checked={this.state.opcedula === "CEDULA"}
                          onChange={this.onChange.bind(this)}
                        />
                        Cedula
                      </label>
                    </div>
                    <div className="radio">
                      <label>
                        <input
                          type="radio"
                          value="PASAPORTE"
                          name="opcedula"
                          checked={this.state.opcedula === "PASAPORTE"}
                          onChange={this.onChange.bind(this)}
                        />
                        Pasaporte
                      </label>
                    </div>
                  </div>
                  <div className="Identificacion col-3">
                    <span className="asterisco1">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="identificacion"
                      id="identificacion"
                      autoComplete="off"
                      maxLength="20"
                      required
                      placeholder="Identificacion: Ej. 12-852-235"
                      onChange={this.onChange.bind(this)}
                      value={this.state.identificacion.toUpperCase()}
                    />
                  </div>
                  {/* <HtmlTooltip title="Seleccione la Fecha de Nacimiento del Estudiante" arrow> */}
                  <div className="fnacimiento col-3">
                    {/* <label className="opcion" >Apellidos.</label> */}
                    <span className="asterisco2">*</span>
                    <input
                      className="form-control"
                      type="date"
                      placeholder="dd/mm/aaaa"
                      pattern="(^(((0[1-9]|1[0-9]|2[0-8])[\/](0[1-9]|1[012]))|((29|30|31)[\/](0[13578]|1[02]))|((29|30)[\/](0[4,6,9]|11)))[\/](19|[2-9][0-9])\d\d$)|(^29[\/]02[\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)"
                      name="fnacimiento"
                      id="fnacimiento"
                      autoComplete="off"
                      required
                      onChange={this.onChange3.bind(this)}
                      value={this.state.fnacimiento}
                    />
                  </div>
                  {/* </HtmlTooltip> */}
                  <div className="edad col-1">
                    {/* <label className="opcion" >Nombres.</label> */}
                    <input
                      className="form-control"
                      type="text"
                      name="edad"
                      id="edad"
                      autoComplete="off"
                      placeholder="Edad:"
                      readOnly="readonly"
                      onChange={this.calcularEdad.bind(this)}
                      value={this.state.edad}
                    />
                  </div>
                  <div className="radio-sexo col-3">
                    <span className="asterisco">*</span>
                    <label className="labelsexo">Sexo: </label>
                    <label>
                      <input
                        type="radio"
                        className="opsexoM"
                        name="opsexo"
                        id="opsexo"
                        value="MASCULINO"
                        checked={this.state.opsexo === "MASCULINO"}
                        onChange={this.onChange.bind(this)}
                      />
                      Masculino
                    </label>
                    <label>
                      <input
                        type="radio"
                        className="opsexo"
                        name="opsexo"
                        id="opsexo"
                        value="FEMENINO"
                        checked={this.state.opsexo === "FEMENINO"}
                        onChange={this.onChange.bind(this)}
                      />
                      Femenino
                    </label>
                  </div>
                </div>
                <div className="fila3-datos-alumno row">
                  <div className="lnacimiento col-3">
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="lnacimiento"
                      id="lnacimiento"
                      autoComplete="off"
                      placeholder="Lugar Nacimiento:"
                      maxLength="20"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.lnacimiento.toUpperCase()}
                    />
                  </div>
                  <div className="nacionalidad col-3">
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="nacionalidad"
                      id="nacionalidad"
                      autoComplete="off"
                      placeholder="Nacionalidad:"
                      maxLength="25"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.nacionalidad.toUpperCase()}
                    />
                  </div>
                  <div className="telefonos col-5">
                    {/* <label className="opcion" >Apellidos.</label> */}
                    <input
                      className="form-control"
                      type="text"
                      name="telefonos"
                      id="telefonos"
                      autoComplete="off"
                      placeholder="Telefonos:"
                      maxLength="30"
                      onChange={this.onChange.bind(this)}
                      value={this.state.telefonos.toUpperCase()}
                    />
                  </div>
                </div>
                <div className="fila4-datos-alumno row">
                  <div className="domicilio col-12">
                    {/* <label className="opcion" >Nombres.</label> */}
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="domicilio"
                      id="domicilio"
                      autoComplete="off"
                      placeholder="Domicilio:"
                      maxLength="70"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.domicilio.toUpperCase()}
                    />
                  </div>
                </div>
                <div className="fila5-datos-alumno row">
                  <div className="personasviven col-12">
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="personasviven"
                      id="personasviven"
                      autoComplete="off"
                      placeholder="Personas con las que vive:"
                      maxLength="60"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.personasviven.toUpperCase()}
                    />
                  </div>
                </div>
                <div className="contenedor-titulo row">
                  <div className="titulo-madre col-12">
                    <p>DATOS DE LA MADRE</p>
                  </div>
                </div>

                <div className="fila1-datos-madre row">
                  <div className="nombremadre col-6">
                    {/* <label className="opcion" >Nombres.</label> */}
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="nombremadre"
                      id="nombremadre"
                      autoComplete="off"
                      placeholder="Nombres:"
                      maxLength="30"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.nombremadre.toUpperCase()}
                    />
                  </div>
                  <div className="apellidomadre col-6">
                    {/* <label className="opcion" >Apellidos.</label> */}
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="apellidomadre"
                      id="apellidomadre"
                      autoComplete="off"
                      placeholder="Apellidos:"
                      maxLength="30"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.apellidomadre.toUpperCase()}
                    />
                  </div>
                </div>
                <div className="fila2-datos-madre row">
                  <div className="radio-identificacion col-1">
                    <div className="radio">
                      <label>
                        <input
                          type="radio"
                          value="CEDULA"
                          name="opcedulaM"
                          checked={this.state.opcedulaM === "CEDULA"}
                          onChange={this.onChange.bind(this)}
                        />
                        Cedula
                      </label>
                    </div>
                    <div className="radio">
                      <label>
                        <input
                          type="radio"
                          value="PASAPORTE"
                          name="opcedulaM"
                          checked={this.state.opcedulaM === "PASAPORTE"}
                          onChange={this.onChange.bind(this)}
                        />
                        Pasaporte
                      </label>
                    </div>
                  </div>
                  <div className="Identificacionmadre col-4">
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="identificacionmadre"
                      id="identificacionmadre"
                      autoComplete="off"
                      placeholder="Identificacion:"
                      maxLength="30"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.identificacionmadre.toUpperCase()}
                    />
                  </div>
                  <div className="ocupacionmadre col-6">
                    {/* <label className="opcion" >Nombres.</label> */}
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="ocupacionmadre"
                      id="ocupacionmadre"
                      autoComplete="off"
                      placeholder="Ocupación/Profesión:"
                      maxLength="30"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.ocupacionmadre.toUpperCase()}
                    />
                  </div>
                </div>
                <div className="fila3-datos-madre row">
                  <div className="ltrabajomadre col-12">
                    {/* <label className="opcion" >Nombres.</label> */}
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="ltrabajomadre"
                      id="ltrabajomadre"
                      autoComplete="off"
                      placeholder="Lugar de Trabajo:"
                      maxLength="50"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.ltrabajomadre.toUpperCase()}
                    />
                  </div>
                </div>
                <div className="fila4-datos-madre row">
                  <div className="telefonotrabajomadre col-6">
                    {/* <span className="asterisco">*</span> */}
                    <input
                      className="form-control"
                      type="text"
                      name="telefonotrabajomadre"
                      id="telefonotrabajomadre"
                      autoComplete="off"
                      placeholder="Telefono de Trabajo:"
                      maxLength="20"
                      onChange={this.onChange.bind(this)}
                      value={this.state.telefonotrabajomadre.toUpperCase()}
                    />
                  </div>
                  <div className="celularmadre col-6">
                    {/* <label className="opcion" >Apellidos.</label> */}
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="celularmadre"
                      id="celularmadre"
                      autoComplete="off"
                      placeholder="Celular:"
                      maxLength="30"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.celularmadre.toUpperCase()}
                    />
                  </div>
                </div>
                <div className="fila5-datos-madre row">
                  <div className="domiciliomadre col-8">
                    {/* <label className="opcion" >Nombres.</label> */}
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="domiciliomadre"
                      id="domiciliomadre"
                      autoComplete="off"
                      placeholder="Domicilio:"
                      maxLength="55"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.domiciliomadre.toUpperCase()}
                    />
                  </div>
                  <div className="telecasamadre col-4">
                    {/* <label className="opcion" >Apellidos.</label> */}
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="telecasamadre"
                      id="telecasamadre"
                      autoComplete="off"
                      placeholder="Telefono de Casa:"
                      maxLength="20"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.telecasamadre.toUpperCase()}
                    />
                  </div>
                </div>
                <div className="fila6-datos-madre row">
                  <div className="correomadre col-6">
                    {/* <label className="opcion" >Nombres.</label> */}
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="correomadre"
                      id="correomadre"
                      autoComplete="off"
                      placeholder="Correo:"
                      maxLength="40"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.correomadre.toUpperCase()}
                    />
                  </div>
                  <div className="nacionalidadmadre col-6">
                    {/* <label className="opcion" >Nombres.</label> */}
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="nacionalidadmadre"
                      id="nacionalidadmadre"
                      autoComplete="off"
                      placeholder="Nacionalidad Principal de la Madre:"
                      maxLength="25"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.nacionalidadmadre.toUpperCase()}
                    />
                  </div>
                </div>
                <div className="contenedor-titulo row">
                  <div className="titulo-padre col-12">
                    <p>DATOS DEL PADRE</p>
                  </div>
                </div>
                <div className="fila1-datos-padre row">
                  <div className="nombrepadre col-6">
                    {/* <label className="opcion" >Nombres.</label> */}
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="nombrepadre"
                      id="nombrepadre"
                      autoComplete="off"
                      placeholder="Nombres:"
                      maxLength="30"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.nombrepadre.toUpperCase()}
                    />
                  </div>
                  <div className="apellidopadre col-6">
                    {/* <label className="opcion" >Apellidos.</label> */}
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="apellidopadre"
                      id="apellidopadre"
                      autoComplete="off"
                      placeholder="Apellidos:"
                      maxLength="30"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.apellidopadre.toUpperCase()}
                    />
                  </div>
                </div>
                <div className="fila2-datos-padre row">
                  <div className="radio-identificacion col-1">
                    <div className="radio">
                      <label>
                        <input
                          type="radio"
                          value="CEDULA"
                          name="opcedulaP"
                          checked={this.state.opcedulaP === "CEDULA"}
                          onChange={this.onChange.bind(this)}
                        />
                        Cedula
                      </label>
                    </div>
                    <div className="radio">
                      <label>
                        <input
                          type="radio"
                          value="PASAPORTE"
                          name="opcedulaP"
                          checked={this.state.opcedulaP === "PASAPORTE"}
                          onChange={this.onChange.bind(this)}
                        />
                        Pasaporte
                      </label>
                    </div>
                  </div>
                  <div className="Identificacionpadre col-4">
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="identificacionpadre"
                      id="identificacionpadre"
                      autoComplete="off"
                      placeholder="Identificacion:"
                      maxLength="30"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.identificacionpadre.toUpperCase()}
                    />
                  </div>
                  <div className="ocupacionpadre col-6">
                    {/* <label className="opcion" >Nombres.</label> */}
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="ocupacionpadre"
                      id="ocupacionpadre"
                      autoComplete="off"
                      placeholder="Ocupación/Profesión:"
                      maxLength="30"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.ocupacionpadre.toUpperCase()}
                    />
                  </div>
                </div>
                <div className="fila3-datos-padre row">
                  <div className="ltrabajopadre col-12">
                    {/* <label className="opcion" >Nombres.</label> */}
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="ltrabajopadre"
                      id="ltrabajopadre"
                      autoComplete="off"
                      placeholder="Lugar de Trabajo:"
                      maxLength="50"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.ltrabajopadre.toUpperCase()}
                    />
                  </div>
                </div>
                <div className="fila4-datos-padre row">
                  <div className="telefonotrabajopadre col-6">
                    {/* <span className="asterisco">*</span> */}
                    <input
                      className="form-control"
                      type="text"
                      name="telefonotrabajopadre"
                      id="telefonotrabajopadre"
                      autoComplete="off"
                      placeholder="Telefono de Trabajo:"
                      maxLength="20"
                      onChange={this.onChange.bind(this)}
                      value={this.state.telefonotrabajopadre.toUpperCase()}
                    />
                  </div>
                  <div className="celularpadre col-6">
                    {/* <label className="opcion" >Apellidos.</label> */}
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="celularpadre"
                      id="celularpadre"
                      autoComplete="off"
                      placeholder="Celular:"
                      maxLength="30"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.celularpadre.toUpperCase()}
                    />
                  </div>
                </div>
                <div className="fila5-datos-padre row">
                  <div className="domiciliopadre col-8">
                    {/* <label className="opcion" >Nombres.</label> */}
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="domiciliopadre"
                      id="domiciliopadre"
                      autoComplete="off"
                      placeholder="Domicilio:"
                      maxLength="55"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.domiciliopadre.toUpperCase()}
                    />
                  </div>
                  <div className="telecasapadre col-4">
                    {/* <label className="opcion" >Apellidos.</label> */}
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="telecasapadre"
                      id="telecasapadre"
                      autoComplete="off"
                      placeholder="Telefono de Casa:"
                      maxLength="20"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.telecasapadre.toUpperCase()}
                    />
                  </div>
                </div>
                <div className="fila6-datos-padre row">
                  <div className="correopadre col-6">
                    {/* <label className="opcion" >Nombres.</label> */}
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="correopadre"
                      id="correopadre"
                      autoComplete="off"
                      placeholder="Correo:"
                      maxLength="40"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.correopadre.toUpperCase()}
                    />
                  </div>
                  <div className="nacionalidadpadre col-6">
                    {/* <label className="opcion" >Nombres.</label> */}
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="nacionalidadpadre"
                      id="nacionalidadpadre"
                      autoComplete="off"
                      placeholder="Nacionalidad Principal del Padre:"
                      maxLength="25"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.nacionalidadpadre.toUpperCase()}
                    />
                  </div>
                </div>
                <div className="contenedor-titulo row">
                  <div className="titulo-admin col-12">
                    <p>DATOS ADMINISTRATIVOS</p>
                  </div>
                </div>

                <div className="fila1-datos-admin row">
                  <div className="fechainicio1 col-1">
                    <label className="fini">Fecha Inicio.</label>
                  </div>
                  <div className="fechainicio col-4">
                    <span className="asterisco">*</span>
                    {/* <HtmlTooltip title="Dia en que el estudiante inicia" arrow> */}
                    <input
                      className="form-control"
                      type="date"
                      placeholder="dd/mm/aaaa"
                      pattern="(^(((0[1-9]|1[0-9]|2[0-8])[\/](0[1-9]|1[012]))|((29|30|31)[\/](0[13578]|1[02]))|((29|30)[\/](0[4,6,9]|11)))[\/](19|[2-9][0-9])\d\d$)|(^29[\/]02[\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)"
                      name="fechainicio"
                      id="fechainicio"
                      autoComplete="off"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.fechainicio}
                    />
                    {/* </HtmlTooltip> */}
                  </div>
                  <div className="nombreescuela col-5">
                    {/* <label className="opcion" >Apellidos.</label> */}
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="nombreescuela"
                      id="nombreescuela"
                      autoComplete="off"
                      placeholder="La escuela anterio fue:"
                      maxLength="40"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.nombreescuela.toUpperCase()}
                    />
                  </div>
                </div>
                <div className="fila1-datos-admin row">
                  <div className="turno col-6">
                    <span className="asterisco">*</span>
                    <select id="cursos" name="cursos" value={this.state.cursos}>
                      <option>--Solo para Uso Administrativo--</option>
                      {/* {this.state.icursos.map((item, i)=>{
                                                return <option  key={item.variable1} value={item.variable1}>{item.variable2}</option>
                                        })} */}
                    </select>
                  </div>

                  <div className="turno col-6">
                    <span className="asterisco">*</span>
                    <select id="grados" name="grados" value={this.state.grados}>
                      <option>--Solo para Uso Administrativo--</option>
                      {/* {this.state.igrado.map((item, i)=>{
                                                return <option  key={item.variable1} value={item.variable1}>{item.variable2}</option>
                                        })} */}
                    </select>
                  </div>
                </div>
                <div className="fila2-datos-admin row">
                  <div className="transporte col-6">
                    {/* <span className="asterisco">*</span> */}
                    <input
                      className="form-control"
                      type="text"
                      name="transporte"
                      id="transporte"
                      autoComplete="off"
                      placeholder="Transporte:"
                      maxLength="30"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.transporte.toUpperCase()}
                    />
                  </div>
                  <div className="personaencargada col-6">
                    {/* <label className="opcion" >Apellidos.</label> */}
                    {/* <span className="asterisco">*</span> */}
                    <input
                      className="form-control"
                      type="text"
                      name="personaencargada"
                      id="personaencargada"
                      autoComplete="off"
                      placeholder="Persona a Cargo:"
                      maxLength="25"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.personaencargada.toUpperCase()}
                    />
                  </div>
                </div>
                <div className="fila3-datos-admin row">
                  <div className="telecontacto col-12">
                    {/* <label className="opcion" >Apellidos.</label> */}
                    {/* <span className="asterisco">*</span> */}
                    <input
                      className="form-control"
                      type="text"
                      name="telecontacto"
                      id="telecontacto"
                      autoComplete="off"
                      placeholder="Telefono Contacto:"
                      maxLength="30"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.telecontacto.toUpperCase()}
                    />
                  </div>
                </div>
                <div className="contenedor-titulo row">
                  <div className="titulo-autorizacion col-12">
                    <p>DATOS PARA AUTORIZACIÓN DE SALIDA</p>
                  </div>
                </div>
                <div className="fila1-datos-auto row">
                  <div className="cont-retirado col-4">
                    <label className="retirado">
                      *El Estudiante podrá se retirado por:{" "}
                    </label>
                  </div>
                  <div className="cont-opcion2 col-1">
                    <label className="opcion">Madre.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="madre"
                      id="madre"
                      value="MADRE"
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion2 col-1">
                    <label className="opcion">Padre.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="padre"
                      id="padre"
                      value="PADRE"
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion2 col-1">
                    <label className="opcion">Nana.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="nana"
                      id="nana"
                      value="NANA"
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion2 col-1">
                    <label className="opcion">Chofer.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="chofer"
                      id="chofer"
                      value="CHOFER"
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion2 col-1">
                    <label className="opcion">Otro.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="otro"
                      id="otro"
                      value="OTRO"
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                </div>
                {this.state.botonNanaOn && (
                  <div className="fila2-datos-auto row">
                    <div className="autorizado1 col-12">
                      {/* <label className="opcion" >Nombres.</label> */}
                      <span className="asterisco">*</span>
                      <input
                        className="form-control"
                        type="text"
                        name="autorizado1"
                        id="autorizado1"
                        autoComplete="off"
                        placeholder="Nombre y Apellido de la Nana"
                        maxLength="50"
                        required
                        onChange={this.onChange.bind(this)}
                        value={this.state.autorizado1.toUpperCase()}
                      />
                    </div>
                  </div>
                )}
                {this.state.botonNanaOn && (
                  <div className="fila3-datos-auto row">
                    <div className="celularperautori1 col-6">
                      {/* <label className="opcion" >Apellidos.</label> */}
                      <span className="asterisco">*</span>
                      <input
                        className="form-control"
                        type="text"
                        name="celularperautori1"
                        id="celularperautori1"
                        autoComplete="off"
                        placeholder="Celular:"
                        maxLength="30"
                        required
                        onChange={this.onChange.bind(this)}
                        value={this.state.celularperautori1.toUpperCase()}
                      />
                    </div>

                    <div className="correoperautori1 col-6">
                      {/* <label className="opcion" >Apellidos.</label> */}
                      <span className="asterisco">*</span>
                      <input
                        className="form-control"
                        type="text"
                        name="correoperautori1"
                        id="correoperautori1"
                        autoComplete="off"
                        placeholder="Correo:"
                        maxLength="30"
                        required
                        onChange={this.onChange.bind(this)}
                        value={this.state.correoperautori1.toUpperCase()}
                      />
                    </div>
                  </div>
                )}
                {this.state.botonChoferOn && (
                  <div className="fila4-datos-auto row">
                    <div className="autorizado2 col-12">
                      {/* <label className="opcion" >Nombres.</label> */}
                      <span className="asterisco">*</span>
                      <input
                        className="form-control"
                        type="text"
                        name="autorizado2"
                        id="autorizado2"
                        autoComplete="off"
                        placeholder="Por favor Ingrese el Nombre y Apellido del Chofer"
                        maxLength="50"
                        required
                        onChange={this.onChange.bind(this)}
                        value={this.state.autorizado2.toUpperCase()}
                      />
                    </div>
                  </div>
                )}
                {this.state.botonChoferOn && (
                  <div className="fila5-datos-auto row">
                    <div className="celularperautori2 col-6">
                      {/* <label className="opcion" >Apellidos.</label> */}
                      <span className="asterisco">*</span>
                      <input
                        className="form-control"
                        type="text"
                        name="celularperautori2"
                        id="celularperautori2"
                        autoComplete="off"
                        placeholder="Celular:"
                        maxLength="30"
                        required
                        onChange={this.onChange.bind(this)}
                        value={this.state.celularperautori2.toUpperCase()}
                      />
                    </div>
                    <div className="correoperautori2 col-6">
                      {/* <label className="opcion" >Apellidos.</label> */}
                      <span className="asterisco">*</span>
                      <input
                        className="form-control"
                        type="text"
                        name="correoperautori2"
                        id="correoperautori2"
                        autoComplete="off"
                        placeholder="Correo:"
                        maxLength="30"
                        required
                        onChange={this.onChange.bind(this)}
                        value={this.state.correoperautori2.toUpperCase()}
                      />
                    </div>
                  </div>
                )}
                {this.state.botonOtroOn && (
                  <div className="fila6-datos-auto row">
                    <div className="autorizado3 col-12">
                      {/* <label className="opcion" >Nombres.</label> */}
                      <span className="asterisco">*</span>
                      <input
                        className="form-control"
                        type="text"
                        name="autorizado3"
                        id="autorizado3"
                        autoComplete="off"
                        placeholder="Por favor Ingrese el Nombre y Apellido"
                        maxLength="50"
                        required
                        onChange={this.onChange.bind(this)}
                        value={this.state.autorizado3.toUpperCase()}
                      />
                    </div>
                  </div>
                )}
                {this.state.botonOtroOn && (
                  <div className="fila7-datos-auto row">
                    <div className="celularperautori3 col-6">
                      {/* <label className="opcion" >Apellidos.</label> */}
                      <span className="asterisco">*</span>
                      <input
                        className="form-control"
                        type="text"
                        name="celularperautori3"
                        id="celularperautori3"
                        autoComplete="off"
                        placeholder="Celular:"
                        maxLength="30"
                        required
                        onChange={this.onChange.bind(this)}
                        value={this.state.celularperautori3.toUpperCase()}
                      />
                    </div>
                    <div className="correoperautori3 col-6">
                      {/* <label className="opcion" >Apellidos.</label> */}
                      <span className="asterisco">*</span>
                      <input
                        className="form-control"
                        type="text"
                        name="correoperautori3"
                        id="correoperautori3"
                        autoComplete="off"
                        placeholder="Correo:"
                        maxLength="30"
                        required
                        onChange={this.onChange.bind(this)}
                        value={this.state.correoperautori3.toUpperCase()}
                      />
                    </div>
                  </div>
                )}
                <div className="contenedor-titulo row">
                  <div className="titulo-notificacion col-12">
                    <p>
                      *RECUERDE NOTIFICAR POR ESCRITO CUANDO EL ESTUDIANTE VAYA
                      A SER RETIRADO POR UNA PERSONA DIFERENTE A LAS SEÑALADAS
                      EN ESTA PLANTILLA. IGUALMENTE DEBE ANEXAR LA
                      IDENTIFICACIÓN, DE LO CONTRARIO NO SE LE PERMITIRÁ LA
                      SALIDA*
                    </p>
                  </div>
                </div>
                <div className="contenedor-titulo row">
                  <div className="titulo-noautorizado col-12">
                    <p>
                      PERSONAS NO AUTORIZADAS DE RETIRAR AL ESTUDIANTE (EN CASO
                      DE QUE EXISTA){" "}
                    </p>
                  </div>
                </div>
                <div className="radio-noautorizado col-8">
                  <span className="asterisco">*</span>
                  <label className="labelnoautorizado">
                    ¿Existe alguna persona que no este autorizada para retirar a
                    el estudiante.?
                  </label>
                  <label>
                    <input
                      type="radio"
                      className="opnoautorizadosi"
                      name="opnoautorizado"
                      id="opnoautorizado"
                      value="SI"
                      checked={this.state.opnoautorizado === "SI"}
                      onChange={this.onChange.bind(this)}
                    />
                    SI
                  </label>
                  <label>
                    <input
                      type="radio"
                      className="opnoautorizadono"
                      name="opnoautorizado"
                      id="opnoautorizado"
                      value="NO"
                      checked={this.state.opnoautorizado === "NO"}
                      onChange={this.onChange.bind(this)}
                    />
                    NO
                  </label>
                </div>
                {this.state.opnoautorizado === "SI" && (
                  <div className="fila1-datos-noautorizado row">
                    <div className="nombrenoauto1 col-6">
                      {/* <label className="opcion" >Apellidos.</label> */}
                      <span className="asterisco">*</span>
                      <input
                        className="form-control"
                        type="text"
                        name="nombrenoauto1"
                        id="nombrenoauto1"
                        autoComplete="off"
                        placeholder="Nombre y Apellido:"
                        maxLength="30"
                        required
                        onChange={this.onChange.bind(this)}
                        value={this.state.nombrenoauto1.toUpperCase()}
                      />
                    </div>
                    <div className="relacionnoauto1 col-5">
                      {/* <label className="opcion" >Apellidos.</label> */}
                      <span className="asterisco">*</span>
                      <input
                        className="form-control"
                        type="text"
                        name="relacionnoauto1"
                        id="relacionnoauto1"
                        autoComplete="off"
                        placeholder="Relacion:"
                        maxLength="20"
                        required
                        onChange={this.onChange.bind(this)}
                        value={this.state.relacionnoauto1.toUpperCase()}
                      />
                    </div>
                    <div className="accionesb col-1">
                      {this.state.btnnoauto1On === "SI" ? (
                        <button
                          className="botonem"
                          onClick={this.botonnoautorizado.bind(this)}
                        >
                          -
                        </button>
                      ) : (
                        <button
                          className="botonem"
                          onClick={this.botonnoautorizado.bind(this)}
                        >
                          +
                        </button>
                      )}
                    </div>
                  </div>
                )}
                {this.state.opnoautorizado === "SI" &&
                  this.state.btnnoauto1On === "SI" && (
                    <div className="fila2-datos-noautorizado row">
                      <div className="nombrenoauto2 col-6">
                        {/* <label className="opcion" >Apellidos.</label> */}
                        <span className="asterisco">*</span>
                        <input
                          className="form-control"
                          type="text"
                          name="nombrenoauto2"
                          id="nombrenoauto2"
                          autoComplete="off"
                          placeholder="Nombre y Apellido:"
                          maxLength="30"
                          required
                          onChange={this.onChange.bind(this)}
                          value={this.state.nombrenoauto2.toUpperCase()}
                        />
                      </div>
                      <div className="relacionnoauto2 col-5">
                        {/* <label className="opcion" >Apellidos.</label> */}
                        <span className="asterisco">*</span>
                        <input
                          className="form-control"
                          type="text"
                          name="relacionnoauto2"
                          id="relacionnoauto2"
                          autoComplete="off"
                          placeholder="Relacion:"
                          maxLength="20"
                          required
                          onChange={this.onChange.bind(this)}
                          value={this.state.relacionnoauto2.toUpperCase()}
                        />
                      </div>
                      <div className="accionesb col-1">
                        {this.state.btnnoauto2On === "SI" ? (
                          <button
                            className="botonem"
                            onClick={this.botonnoautorizado2.bind(this)}
                          >
                            -
                          </button>
                        ) : (
                          <button
                            className="botonem"
                            onClick={this.botonnoautorizado2.bind(this)}
                          >
                            +
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                {this.state.opnoautorizado === "SI" &&
                  this.state.btnnoauto2On === "SI" && (
                    <div className="fila3-datos-noautorizado row">
                      <div className="nombrenoauto3 col-6">
                        {/* <label className="opcion" >Apellidos.</label> */}
                        <span className="asterisco">*</span>
                        <input
                          className="form-control"
                          type="text"
                          name="nombrenoauto3"
                          id="nombrenoauto3"
                          autoComplete="off"
                          placeholder="Nombre y Apellido:"
                          maxLength="30"
                          required
                          onChange={this.onChange.bind(this)}
                          value={this.state.nombrenoauto3.toUpperCase()}
                        />
                      </div>
                      <div className="relacionnoauto3 col-5">
                        {/* <label className="opcion" >Apellidos.</label> */}
                        <span className="asterisco">*</span>
                        <input
                          className="form-control"
                          type="text"
                          name="relacionnoauto3"
                          id="relacionnoauto3"
                          autoComplete="off"
                          placeholder="Relacion:"
                          maxLength="20"
                          required
                          onChange={this.onChange.bind(this)}
                          value={this.state.relacionnoauto3.toUpperCase()}
                        />
                      </div>
                    </div>
                  )}
                <div className="contenedor-titulo row">
                  <div className="titulo-notificacion col-12">
                    <p>
                      POR SEGURIDAD ES IMPORTANTE NOTIFICAR POR ESCRITO CUANDO
                      UN EMPLEADO AUTORIZADO DEJA DE PRESTAR SERVICIOS A LA
                      FAMILIA. IGUALMENTE CUANDO EXISTA MEDIDAS LEGALES PARA
                      IMPEDIR A UNA PERSONA TENER CONTACTO CON EL ESTUDIANTE.
                    </p>
                  </div>
                </div>
                <div className="contenedor-titulo row">
                  <div className="titulo-noautorizado col-12">
                    <p>CONTACTOS DE EMERGENCIAS </p>
                  </div>
                </div>
                <div className="fila1-datos-emergencia row">
                  <div className="nombreemergencia col-6">
                    {/* <label className="opcion" >Apellidos.</label> */}
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="nombreemergencia"
                      id="nombreemergencia"
                      autoComplete="off"
                      placeholder="Nombre y Apellido:"
                      maxLength="30"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.nombreemergencia.toUpperCase()}
                    />
                  </div>
                  <div className="relacionemergencia col-6">
                    {/* <label className="opcion" >Apellidos.</label> */}
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="relacionemergencia"
                      id="relacionemergencia"
                      autoComplete="off"
                      placeholder="Relación:"
                      maxLength="20"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.relacionemergencia.toUpperCase()}
                    />
                  </div>
                </div>
                <div className="fila2-datos-emergencia row">
                  <div className="tlfemergencia col-10">
                    {/* <label className="opcion" >Apellidos.</label> */}
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="tlfemergencia"
                      id="tlfemergencia"
                      autoComplete="off"
                      placeholder="Teléfonos:"
                      maxLength="70"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.tlfemergencia.toUpperCase()}
                    />
                  </div>
                  <div className="accionesb col-2">
                    {this.state.btnemOn ? (
                      <button
                        className="botonem"
                        onClick={this.botonemergencia.bind(this)}
                      >
                        -
                      </button>
                    ) : (
                      <button
                        className="botonem"
                        onClick={this.botonemergencia.bind(this)}
                      >
                        +
                      </button>
                    )}
                  </div>
                </div>
                {this.state.btnemOn && (
                  <div className="fila1-datos-emergencia row">
                    <div className="nombreemergencia col-6">
                      {/* <label className="opcion" >Apellidos.</label> */}
                      <span className="asterisco">*</span>
                      <input
                        className="form-control"
                        type="text"
                        name="nombreemergencia2"
                        id="nombreemergencia2"
                        autoComplete="off"
                        placeholder="Nombre y Apellido:"
                        maxLength="30"
                        required
                        onChange={this.onChange.bind(this)}
                        value={this.state.nombreemergencia2.toUpperCase()}
                      />
                    </div>
                    <div className="relacionemergencia col-6">
                      {/* <label className="opcion" >Apellidos.</label> */}
                      <span className="asterisco">*</span>
                      <input
                        className="form-control"
                        type="text"
                        name="relacionemergencia2"
                        id="relacionemergencia2"
                        autoComplete="off"
                        placeholder="Relación:"
                        maxLength="20"
                        required
                        onChange={this.onChange.bind(this)}
                        value={this.state.relacionemergencia2.toUpperCase()}
                      />
                    </div>
                  </div>
                )}
                {this.state.btnemOn && (
                  <div className="fila2-datos-emergencia row">
                    <div className="tlfemergencia col-10">
                      {/* <label className="opcion" >Apellidos.</label> */}
                      <span className="asterisco">*</span>
                      <input
                        className="form-control"
                        type="text"
                        name="tlfemergencia2"
                        id="tlfemergencia2"
                        autoComplete="off"
                        placeholder="Teléfonos:"
                        maxLength="70"
                        required
                        onChange={this.onChange.bind(this)}
                        value={this.state.tlfemergencia2.toUpperCase()}
                      />
                    </div>
                    <div className="accionesb col-2">
                      {this.state.btnem2On ? (
                        <button
                          className="botonem"
                          onClick={this.botonemergencia2.bind(this)}
                        >
                          -
                        </button>
                      ) : (
                        <button
                          className="botonem"
                          onClick={this.botonemergencia2.bind(this)}
                        >
                          +
                        </button>
                      )}
                    </div>
                  </div>
                )}
                {this.state.btnem2On && (
                  <div className="fila1-datos-emergencia row">
                    <div className="nombreemergencia col-6">
                      {/* <label className="opcion" >Apellidos.</label> */}
                      <span className="asterisco">*</span>
                      <input
                        className="form-control"
                        type="text"
                        name="nombreemergencia3"
                        id="nombreemergencia3"
                        autoComplete="off"
                        placeholder="Nombre y Apellido:"
                        maxLength="30"
                        required
                        onChange={this.onChange.bind(this)}
                        value={this.state.nombreemergencia3.toUpperCase()}
                      />
                    </div>
                    <div className="relacionemergencia col-6">
                      {/* <label className="opcion" >Apellidos.</label> */}
                      <span className="asterisco">*</span>
                      <input
                        className="form-control"
                        type="text"
                        name="relacionemergencia3"
                        id="relacionemergencia3"
                        autoComplete="off"
                        placeholder="Relación:"
                        maxLength="20"
                        required
                        onChange={this.onChange.bind(this)}
                        value={this.state.relacionemergencia3.toUpperCase()}
                      />
                    </div>
                  </div>
                )}
                {this.state.btnem2On && (
                  <div className="fila2-datos-emergencia row">
                    <div className="tlfemergencia col-10">
                      {/* <label className="opcion" >Apellidos.</label> */}
                      <span className="asterisco">*</span>
                      <input
                        className="form-control"
                        type="text"
                        name="tlfemergencia3"
                        id="tlfemergencia3"
                        autoComplete="off"
                        placeholder="Teléfonos:"
                        maxLength="70"
                        required
                        onChange={this.onChange.bind(this)}
                        value={this.state.tlfemergencia3.toUpperCase()}
                      />
                    </div>
                  </div>
                )}

                <div className="contenedor-titulo row">
                  <div className="titulo-antecedentesescolar col-12">
                    <p>ANTECEDENTE DEL ALUMNO </p>
                  </div>
                </div>

                <div className="fila1-datos-antecedentes row">
                  <div className="radio-antecedentes col-12">
                    <span className="asterisco">*</span>
                    <label className="labelantecedentes">
                      Indique si tiene alguna o posible condición: :{" "}
                    </label>
                    <label>
                      <input
                        type="radio"
                        className="opantecedentessi"
                        name="opantecedentes"
                        id="opantecedentes"
                        value="SI"
                        checked={this.state.opantecedentes === "SI"}
                        onChange={this.onChange.bind(this)}
                      />
                      SI
                    </label>
                    <label>
                      <input
                        type="radio"
                        className="opantecedentesno"
                        name="opantecedentes"
                        id="opantecedentes"
                        value="NO"
                        checked={this.state.opantecedentes === "NO"}
                        onChange={this.onChange.bind(this)}
                      />
                      NO
                    </label>
                  </div>
                </div>

                <div className="fila2-datos-antecedentes row">
                  <div className="antecedentes col-12">
                    {/* <label className="opcion" >Apellidos.</label> */}
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="antecedentes"
                      id="antecedentes"
                      autoComplete="off"
                      placeholder="Tipo de Antecedentes:"
                      maxLength="50"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.antecedentes.toUpperCase()}
                    />
                  </div>
                </div>
                
                <div className="fila4-datos-antecedentesescolar row">
                  <div className="padeceenfermedad col-12">
                    {/* <label className="opcion" >Apellidos.</label> */}
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="padeceenfermedad"
                      id="padeceenfermedad"
                      autoComplete="off"
                      maxLength="70"
                      required
                      placeholder="Si la Madre o el Padre padece de alguna enfermedad o condicion especial, Especifique:"
                      onChange={this.onChange.bind(this)}
                      value={this.state.padeceenfermedad.toUpperCase()}
                    />
                  </div>
                </div>
                <div className="fila5-datos-antecedentesescolar row">
                  <div className="radio-antecedentes col-5">
                    <span className="asterisco">*</span>
                    <label className="labelantecedentesescolar">
                      ¿El estudiante ha estado hospitalizado:?{" "}
                    </label>
                    <label>
                      <input
                        type="radio"
                        className="ophospitalizadosi"
                        name="ophospitalizado"
                        id="ophospitalizado"
                        value="SI"
                        checked={this.state.ophospitalizado === "SI"}
                        onChange={this.onChange.bind(this)}
                      />
                      SI
                    </label>
                    <label>
                      <input
                        type="radio"
                        className="ophospitalizadono"
                        name="ophospitalizado"
                        id="ophospitalizado"
                        value="NO"
                        checked={this.state.ophospitalizado === "NO"}
                        onChange={this.onChange.bind(this)}
                      />
                      NO
                    </label>
                  </div>
                  {this.state.ophospitalizado === "SI" && (
                    <div className="ocaciones col-5">
                      {/* <label className="opcion" >Apellidos.</label> */}
                      <span className="asterisco">*</span>
                      <input
                        className="form-control"
                        type="text"
                        name="ocaciones"
                        id="ocaciones"
                        autoComplete="off"
                        placeholder="Nro. de Ocaciones:"
                        maxLength="25"
                        required
                        onChange={this.onChange.bind(this)}
                        value={this.state.ocaciones.toUpperCase()}
                      />
                    </div>
                  )}
                </div>
                <div className="fila6-datos-antecedentesescolar row">
                  <div className="radio-antecedentes col-5">
                    <span className="asterisco">*</span>
                    <label className="labelantecedentesescolar">
                      ¿El estudiante ha sido operado:?{" "}
                    </label>
                    <label>
                      <input
                        type="radio"
                        className="opoperadosi"
                        name="opoperado"
                        id="opoperado"
                        value="SI"
                        checked={this.state.opoperado === "SI"}
                        onChange={this.onChange.bind(this)}
                      />
                      SI
                    </label>
                    <label>
                      <input
                        type="radio"
                        className="opoperadono"
                        name="opoperado"
                        id="opoperado"
                        value="NO"
                        checked={this.state.opoperado === "NO"}
                        onChange={this.onChange.bind(this)}
                      />
                      NO
                    </label>
                  </div>
                  {this.state.opoperado === "SI" && (
                    <div className="operadocausa col-6">
                      {/* <label className="opcion" >Apellidos.</label> */}
                      <span className="asterisco">*</span>
                      <input
                        className="form-control"
                        type="text"
                        name="operadocausa"
                        id="operadocausa"
                        autoComplete="off"
                        maxLength="30"
                        required
                        placeholder="Si el niño(a) fue operado, favor idicar la causa:"
                        onChange={this.onChange.bind(this)}
                        value={this.state.operadocausa.toUpperCase()}
                      />
                    </div>
                  )}
                </div>
                <div className="fila7-datos-antecedentesescolar row">
                  <div className="conductapositiva col-8">
                    <span className="asterisco">*</span>
                    <label className="labelcondicion">
                      ¿Padece el estudiante de alguna enfermedad o condición
                      especial:?{" "}
                    </label>
                    <label>
                      <input
                        type="radio"
                        className="opcondicionsi"
                        name="opcondicion"
                        id="opcondicion"
                        value="SI"
                        checked={this.state.opcondicion === "SI"}
                        onChange={this.onChange.bind(this)}
                      />
                      SI
                    </label>
                    <label>
                      <input
                        type="radio"
                        className="opcondicionno"
                        name="opcondicion"
                        id="opcondicion"
                        value="NO"
                        checked={this.state.opcondicion === "NO"}
                        onChange={this.onChange.bind(this)}
                      />
                      NO
                    </label>
                  </div>
                  {this.state.opcondicion === "SI" && (
                    <div className="especifique col-5">
                      {/* <label className="opcion" >Apellidos.</label> */}
                      <span className="asterisco">*</span>
                      <input
                        className="form-control"
                        type="text"
                        name="especifique"
                        id="especifique"
                        autoComplete="off"
                        maxLength="20"
                        required
                        placeholder="Especifique:"
                        onChange={this.onChange.bind(this)}
                        value={this.state.especifique.toUpperCase()}
                      />
                    </div>
                  )}
                </div>
                <div className="fila8-datos-antecedentesescolar row">
                  <div className="medicamento col-4">
                    <span className="asterisco">*</span>
                    <label className="labelantecedentesescolar">
                      ¿Toma algún medícamento:?{" "}
                    </label>
                    <label>
                      <input
                        type="radio"
                        className="opmedicamentosi"
                        name="opmedicamento"
                        id="opmedicamento"
                        value="SI"
                        checked={this.state.opmedicamento === "SI"}
                        onChange={this.onChange.bind(this)}
                      />
                      SI
                    </label>
                    <label>
                      <input
                        type="radio"
                        className="opmedicamentono"
                        name="opmedicamento"
                        id="opmedicamento"
                        value="NO"
                        checked={this.state.opmedicamento === "NO"}
                        onChange={this.onChange.bind(this)}
                      />
                      NO
                    </label>
                  </div>
                  {this.state.opmedicamento === "SI" && (
                    <div className="especifiquemedicamento col-5">
                      {/* <label className="opcion" >Apellidos.</label> */}
                      <span className="asterisco">*</span>
                      <input
                        className="form-control"
                        type="text"
                        name="especifiquemedicamento"
                        id="especifiquemedicamento"
                        autoComplete="off"
                        placeholder="Especifique:"
                        maxLength="30"
                        required
                        onChange={this.onChange.bind(this)}
                        value={this.state.especifiquemedicamento.toUpperCase()}
                      />
                    </div>
                  )}
                </div>
                <div className="fila9-datos-antecedentesescolar row">
                  <div className="vacunacion col-4">
                    <span className="asterisco">*</span>
                    <label className="labelvacunacion">Vacunación: </label>
                    <label>
                      <input
                        type="radio"
                        className="opvacunacionsi"
                        name="opvacunacion"
                        id="opvacunacion"
                        value="COMPLETA"
                        checked={this.state.opvacunacion === "COMPLETA"}
                        onChange={this.onChange.bind(this)}
                      />
                      Completa
                    </label>
                    <label>
                      <input
                        type="radio"
                        className="opvacunacionno"
                        name="opvacunacion"
                        id="opvacunacion"
                        value="INCOMPLETA"
                        checked={this.state.opvacunacion === "INCOMPLETA"}
                        onChange={this.onChange.bind(this)}
                      />
                      Incompleta
                    </label>
                  </div>
                  <div className="peso col-2">
                    {/* <label className="opcion" >Apellidos.</label> */}
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="peso"
                      id="peso"
                      autoComplete="off"
                      placeholder="Peso:"
                      maxLength="10"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.peso.toUpperCase()}
                    />
                  </div>
                  <div className="talla col-2">
                    {/* <label className="opcion" >Apellidos.</label> */}
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="number"
                      name="talla"
                      id="talla"
                      autoComplete="off"
                      placeholder="Altura:"
                      maxLength="10"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.talla}
                    />
                  </div>
                  <div className="sangre col-2">
                    {/* <label className="opcion" >Apellidos.</label> */}
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="sangre"
                      id="sangre"
                      autoComplete="off"
                      placeholder="Sangre:"
                      onChange={this.onChange.bind(this)}
                      maxLength="10"
                      required
                      value={this.state.sangre.toUpperCase()}
                    />
                  </div>
                </div>
                <div className="fila10-datos-antecedentesescolar row">
                  <div className="cont-retirado col-2">
                    <span className="asterisco4">*</span>
                    <label className="labelalergico">Es alergico a: </label>
                  </div>
                  <div className="cont-opcion22">
                    <label className="opcion2">Penicilina.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="penicilina"
                      id="penicilina"
                      value="PENICILINA"
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion22">
                    <label className="opcion2">Mariscos.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="mariscos"
                      id="mariscos"
                      value="MARISCOS"
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion22">
                    <label className="opcion2">Yodo.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="yodo"
                      id="yodo"
                      value="YODO"
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion22">
                    <label className="opcion2">Merthiolate.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="merthiolate"
                      id="merthiolate"
                      value="MERTHIOLATE"
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion22">
                    <label className="opcion2">Aspirina.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="aspirina"
                      id="aspirina"
                      value="ASPIRINA"
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion22">
                    <label className="opcion2">Citrico.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="citrico"
                      id="citrico"
                      value="CITRICO"
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion22">
                    <label className="opcion2">Lactosa.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="laptosa"
                      id="laptosa"
                      value="LACTOSA"
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion22">
                    <label className="opcion2">Otro.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="otroa"
                      id="otroa"
                      value="OTRO"
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  {this.state.otroa && (
                    <div className="otroalergica col-3">
                      {/* <label className="opcion" >Apellidos.</label> */}
                      {/* <span className="asterisco3">*</span> */}
                      <input
                        className="form-control"
                        type="text"
                        name="otroalergica"
                        id="otroalergica"
                        autoComplete="off"
                        placeholder="Otro:"
                        maxLength="12"
                        required
                        onChange={this.onChange.bind(this)}
                        value={this.state.otroalergica.toUpperCase()}
                      />
                    </div>
                  )}
                  {!this.state.otroa && (
                    <div className="cont-opcion22 col-1">
                      <label className="opcion2">Ninguna.</label>
                      <input
                        className="form-control"
                        type="checkbox"
                        name="ninguna"
                        id="ninguna"
                        value="NINGUNA"
                        onChange={this.onChange2.bind(this)}
                      />
                    </div>
                  )}
                </div>
                <div className="fila11-datos-antecedentesescolar row">
                  <div className="cont-retirado col-2">
                    <span className="asterisco4">*</span>
                    <label className="labelenfermedades">Ha sufrido de:</label>
                  </div>
                  <div className="cont-opcion22">
                    <label className="opcion2">Convulsiones.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="convulsiones"
                      id="convulsiones"
                      value="CONVULSIONES"
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion22">
                    <label className="opcion2">Asma.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="asma"
                      id="asma"
                      value="ASMA"
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion22">
                    <label className="opcion2">Desmayo.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="desmayo"
                      id="desmayo"
                      value="DESMAYO"
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion22">
                    <label className="opcion2">Diabetes.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="diabetes"
                      id="diabetes"
                      value="DIABETES"
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion22">
                    <label className="opcion2">Rubeola.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="rubeola"
                      id="rubeola"
                      value="RUBEOLA"
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion22">
                    <label className="opcion2">Papera.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="papera"
                      id="papera"
                      value="PAPERA"
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion22">
                    <label className="opcion2">Sarampión.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="sarampion"
                      id="sarampion"
                      value="SARAMPION"
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion22">
                    <label className="opcion2">Varicela.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="varicela"
                      id="varicela"
                      value="VARICELA"
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion22">
                    <label className="opcion2">Tosferina.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="tosferina"
                      id="tosferina"
                      value="TOSFERINA"
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion22">
                    <label className="opcion2">Hepatitis.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="hepatitis"
                      id="hepatitis"
                      value="HEPATITIS"
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion22">
                    <label className="opcion2">Amebiasis.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="amebiasis"
                      id="amebiasis"
                      value="AMEBIASIS"
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion22">
                    <label className="opcion2">Otro.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="otroen"
                      id="otroen"
                      value="OTRO"
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  {this.state.otroen && (
                    <div className="otroalergica">
                      {/* <label className="opcion" >Apellidos.</label> */}
                      {/* <span className="asterisco3">*</span> */}
                      <input
                        className="form-control"
                        type="text"
                        name="otroenfermedad"
                        id="otroenfermedad"
                        autoComplete="off"
                        placeholder="Otro:"
                        maxLength="12"
                        required
                        onChange={this.onChange.bind(this)}
                        value={this.state.otroenfermedad.toUpperCase()}
                      />
                    </div>
                  )}
                  {!this.state.otroen && (
                    <div className="cont-opcion22 col-1">
                      <label className="opcion2">Ninguna.</label>
                      <input
                        className="form-control"
                        type="checkbox"
                        name="ningunae"
                        id="ningunae"
                        value="NINGUNA"
                        onChange={this.onChange2.bind(this)}
                      />
                    </div>
                  )}
                </div>

                <div className="contenedor-titulo row">
                  <div className="titulo-notificacion col-12">
                    <p>AUTORIZACIONES.</p>
                  </div>
                </div>
                <div className="fila1-datos-emergencia row">
                  <div className="representante col-12">
                    <span className="asterisco">*</span>
                    <label className="labelantecedentesescolar">
                      El Acudiente permite tomar fotos y grabaciones a el
                      estudiante para ser publicada exclusivamente en sus redes
                      sociales.{" "}
                    </label>
                    <label>
                      <input
                        type="radio"
                        className="opredessocialessi"
                        name="opredessociales"
                        id="opredessociales"
                        value="SI"
                        checked={this.state.opredessociales === "SI"}
                        onChange={this.onChange.bind(this)}
                      />
                      SI
                    </label>
                    <label>
                      <input
                        type="radio"
                        className="opredessocialesno"
                        name="opredessociales"
                        id="opredessociales"
                        value="NO"
                        checked={this.state.opredessociales === "NO"}
                        onChange={this.onChange.bind(this)}
                      />
                      NO
                    </label>
                  </div>
                  <div className="representante col-12">
                    <span className="asterisco">*</span>
                    <label className="labelantecedentesescolar">
                      El Acudiente permite Instruir a el estudiante en las
                      clases de FE, basada en la biblia y sus evangelios.{" "}
                    </label>
                    <label>
                      <input
                        type="radio"
                        className="opevangeliosi"
                        name="opevangelio"
                        id="opevangelio"
                        value="SI"
                        checked={this.state.opevangelio === "SI"}
                        onChange={this.onChange.bind(this)}
                      />
                      SI
                    </label>
                    <label>
                      <input
                        type="radio"
                        className="opevangeliono"
                        name="opevangelio"
                        id="opevangelio"
                        value="NO"
                        checked={this.state.opevangelio === "NO"}
                        onChange={this.onChange.bind(this)}
                      />
                      NO
                    </label>
                  </div>
                </div>
                <div className="contenedor-titulo row">
                  <div className="titulo-noautorizado col-12">
                    <p>ACUDIENTE DEL ALUMNO</p>
                  </div>
                </div>
                <div className="contenedor-titulo row">
                  <div className="titulo-notificacion col-12">
                    <p>
                      PARA EFECTO DEL ACUDIENTE ES IMPORTANTE TENER EL
                      CONOCIMIENTO QUE EL PADRE QUE SEA ASIGNADO COMO ACUDIENTE
                      DEL ALUMNO, CUALQUIER TIPO DE DOCUMENTACIÓN ADMINISTRATIVA
                      O ACADEMICA SERA DIRIGIDO A SU NOMBRE.
                    </p>
                  </div>
                </div>
                <div className="fila1-datos-emergencia row">
                  <div className="representante col-6">
                    <span className="asterisco">*</span>
                    <label className="labelantecedentesescolar">
                      Por favor seleccione quien será el acudiente:{" "}
                    </label>
                    <label>
                      <input
                        type="radio"
                        className="oprepresentantesi"
                        name="oprepresentante"
                        id="oprepresentante"
                        value="MADRE"
                        checked={this.state.oprepresentante === "MADRE"}
                        onChange={this.onChange.bind(this)}
                      />
                      Madre
                    </label>
                    <label>
                      <input
                        type="radio"
                        className="oprepresentanteno"
                        name="oprepresentante"
                        id="oprepresentante"
                        value="PADRE"
                        checked={this.state.oprepresentante === "PADRE"}
                        onChange={this.onChange.bind(this)}
                      />
                      Padre
                    </label>
                  </div>
                </div>
                <div className="fila1-datos-alumno row">
                  <div className="nombrerecibo col-6">
                    {/* <label className="opcion" >Nombres.</label> */}
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="nombrerecibo"
                      id="nombrerecibo"
                      autoComplete="off"
                      placeholder="Los recibos se emiten a nombre de:"
                      maxLength="50"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.nombrerecibo.toUpperCase()}
                    />
                  </div>
                  <div className="apellido col-6">
                    {/* <label className="opcion" >Apellidos.</label> */}
                    <span className="asterisco">*</span>
                    <input
                      className="form-control"
                      type="text"
                      name="cedularecibo"
                      id="cedularecibo"
                      autoComplete="off"
                      placeholder="Cédula o Ruc:"
                      maxLength="30"
                      required
                      onChange={this.onChange.bind(this)}
                      value={this.state.cedularecibo.toUpperCase()}
                    />
                  </div>
                </div>
                <div className="accionesb col-12">
                  <button
                    className="botonm"
                    onClick={this.vistapreviaInscripcion.bind(this)}
                  >
                    Vista Previa Inscripción
                  </button>
                  <button
                    className="botonm"
                    onClick={this.vistapreviaContrato.bind(this)}
                  >
                    Vista Previa Contrato
                  </button>
                  {/* <button className="botonm" onClick={this.vistapreviaContratoCovid.bind(this)}>Vista Previa Contrato Covid</button> */}
                  <button
                    className="botonm"
                    onClick={this.vistapreviaPoliticas.bind(this)}
                  >
                    Vista Previa Políticas
                  </button>
                  <button
                    className="botonm"
                    onClick={this.vistapreviaAutorizacion.bind(this)}
                  >
                    Vista Previa Autorización
                  </button>
                  <button className="botonm" onClick={this.guardar.bind(this)}>
                    Enviar Formulario
                  </button>
                </div>
              </div>
            )}
            {this.state.btnAceptarOn && (
              <div className="titulo-pago col-12">
                <p>
                  LA PLANILLA DE INSCRIPCIÓN FUE ENVIADA EXITOSAMENTE, LA
                  INSTITUCION LE ENVIARA UN CORREO UNA VEZ REVISADA LA
                  INFORMACIÓN ENVIADA.{" "}
                </p>
                <button className="botonm" onClick={this.limpiar.bind(this)}>
                  Continuar
                </button>
              </div>
            )}
            {this.state.mensajealerta && (
              <div className="contenedor-titulox row">
                <div className="titulo-notificacionx col-12">
                  <p>{this.state.mensaje}</p>
                  <button className="botonm" onClick={this.mensajeA.bind(this)}>
                    Continuar
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default PlanillaP;
