import React, { Component } from 'react';
import axios from 'axios';
import FeatherIcon from 'feather-icons-react';
import RingLoader from 'react-spinners/ScaleLoader';
import {Link} from "react-router-dom";



//componentes
import './subirarchivos.css';

class SubirArchivos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            botonOn:true,
            mensajealerta:false,
            rutasArchivos:'',
            irutas:[], 
            identificacion:'',
            identificacion2:'',
            paso:false,
            btnOK:false,
            mensaje:'',
            loading:false,

        }
       
      
    }

    onChange(e) {
        //console.log(e.target.name)
        this.setState({
            [e.target.name]: e.target.value
             
        })
    }
    limpiar = () => {
        this.setState({
            botonOn:true,
            mensajealerta:false,
            rutasArchivos:'',
            irutas:[], 
            identificacion:'',
            identificacion2:'',
            paso:false,
            mensaje:'',
            loading:false,

        })
    }

    eliarchivo=(e,i)=>{
        //console.log(e);
        this.setState({
            loading:true,
        });
        let data = new FormData();
        data.append('cedulaRepresentante', this.state.identificacion);
        data.append('cedulaAlumno', this.state.identificacion2);
        data.append('archivoArriba', e); 
        data.append('boton', 'Eliminar'); 
        let config = {
            headers: {
              'Content-Type': 'text/html'}
        };
        const xurl = 'https://institutobilinguelasnaciones.com/backend/archivo.php';
        axios.post(xurl, data, config)

        .then(res => {
            this.setState({
                loading:false,
            });
            if (res.data.variable1) {
                let xrutas = res.data.variable3
                this.setState({
                    irutas:xrutas,
                });
            }else {            
                if (res.data.variable2.length>0){
                    this.setState({
                        paso:true,
                        mensaje:res.data.variable2
                    });    
                }else{
                    this.setState({
                        paso:true,
                        mensaje:'Es posible que uno o mas archivos no fueron guardados en el servidor'
                    });    

                }
            }
            //console.log(res);
        })
    
    }

    handleClose = () => {
        this.setState({ mensajealerta: false })
    }
    Upload=(e)=> {
        //console.log(e.target.files)
        this.setState({
            loading:true,
        });
        const form_data = new FormData()
        
        let name_fvar=''
        for(let x =0 ;x<e.target.files.length;x++){
            name_fvar='file_'+String(x+1)
            form_data.append(name_fvar,e.target.files[x])
            //console.log(name_fvar)
        }
        form_data.append('cedulaRepresentante', this.state.identificacion);
        form_data.append('cedulaAlumno', this.state.identificacion2);
        form_data.append('boton', 'Guardar');
        let config = {
            headers: {
              'Content-Type': 'multipart/form-data'}
        };
        const xurl = 'https://institutobilinguelasnaciones.com/backend/archivo.php';
        axios.post(xurl, form_data, config)

        .then(res => {
            this.setState({
                loading:false,
            });
            if (res.data.variable1) {
                alert ('Los Documentos fueron enviados exitosamente');
                let xrutas = res.data.variable3
                this.setState({
                    irutas:xrutas,
                });
            }else {            
                if (res.data.variable2.length>0){
                    this.setState({
                        paso:true,
                        mensaje:res.data.variable2
                    });    
                }else{
                    this.setState({
                        paso:true,
                        mensaje:'Es posible que uno o mas archivos no fueron guardados en el servidor'
                    });    

                }
            }
            //console.log(res);
        })
    }
    
    ClickUpload = (e) => {
        if (this.state.identificacion.length>0 && this.state.identificacion2.length>0){
            document.getElementById('upload-file').click()
        }else{
            this.setState({
                paso:true,
                mensaje:'Sr(a) Representante deberá introducir la  Cédula/Pasaporte del Alumno y la del Acudiente como se le esta solicitando gracias....'
            });

        }   
    }


    render(){
     
        return(
                <div className="cont-servi-principal row">
                    <div className="eslogan1su1 col-12">
                        <div>
                            <p className="titulosu1">Modulo para Subir Archivos a la Institución.</p>
                        </div>
                        {this.state.loading &&
                            <div className="cont-spinner row">
                                <div className='spinner col-6'>
                                <RingLoader
                                    clasName="spinner"
                                    sizeUnit={"px"}
                                    size={160}
                                    width={19}
                                    radius={20}
                                    height={160}
                                    color={'#48e120'}
                                    loading={this.state.loading}
                                />
                                </div>
                            </div>
                        }

                        {!this.state.paso &&
                        <div className="titulo-notificacion row">
                            <p>Deberá colocar la Cédula/Pasaporte del representante y Alumno para asociar los archivos con su registro en el colegio, Los archivos seleccionados serán enviado a el servidor directamente.</p>
                        </div>
                        }
                        {!this.state.paso &&
                        <div className="contenido1 row">    
                            <div className="form-groupef1 col-12">
                                <div className="cont-buscar1 row" >
                                    <div className="identificacionr col-3">              
                                        <span className="asterisco">*
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="identificacion"
                                            id="identificacion"
                                            autoComplete="off"
                                            placeholder="Identidad Acudiente:"
                                            maxLength="30"
                                            required
                                            onChange={this.onChange.bind(this)}
                                            value={this.state.identificacion}
                                        />
                                        </span>
                                    </div>
                                    <div className="identificacionr col-3">              
                                        <span className="asterisco">*
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="identificacion2"
                                            id="identificacion2"
                                            autoComplete="off"
                                            placeholder="Identidad Alumno:"
                                            maxLength="30"
                                            required
                                            onChange={this.onChange.bind(this)}
                                            value={this.state.identificacion2}
                                        />
                                        </span>
                                    </div>
                                    <div className="identificacionr2 col-4" onClick={this.ClickUpload.bind(this)} >                                    
                                        <p className="subirtexto1">Haz click para subir tus archivos</p>
                                        <br />
                                        <FeatherIcon icon="upload" size="30px" stroke="#999" />
                                        <input
                                            onChange={this.Upload.bind(this)} 
                                            style={{ display: 'none' }}
                                            id="upload-file" 
                                            type="file" 
                                            multiple  
                                        />
                                    </div>    
                                </div>    
                                <div className="preview row">
                                    <div className="titulo-notificacion col-12">
                                        <p>Archivos ya enviados a el servidor.</p>
                                    </div>
                                    {this.state.irutas.map((item, i)=>{
                                        return <div className="cont-foto"><span className="cerrar" onClick={this.eliarchivo.bind(this, item.variable5)}>X</span> <a href= {'https://institutobilinguelasnaciones.com/backend/'+item.variable4} target="__blank"> <img src={'https://institutobilinguelasnaciones.com/backend/'+item.variable4} key={item.variable5} alt={item.variable5} className="fotos" name={item.variable5}/> </a></div>
                                    })}    
                                </div>
                            </div>
                        </div>
                        }
                        {this.state.paso &&
                        <div className="row">    
                            <div className="titulo-notificacion col-12">
                        <p>{this.state.mensaje}</p>
                            </div>
                            <div className="accionesb col-12">
                                <button className="botonm" onClick={this.limpiar.bind(this)}>Continuar</button>
                            </div> 
                        </div>
                        }
                    </div>
                </div>
            
        )
    }
}export default SubirArchivos;    