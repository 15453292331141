import React, { Component } from 'react';

//componentes
import PlanillaPC from './planillapc'

class Index extends Component {

    render(){
        return(
            <div>
                <PlanillaPC />
            </div>
        );
    }

}
export default Index;