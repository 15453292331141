import React, { Component } from 'react';

//componentes
import Fotos from './fotos'

class Index extends Component {

    render(){
        return(
            <div>
                <Fotos />
            </div>
        );
    }

}
export default Index;