import React, { Component } from 'react';
//import axios from 'axios';
import {Link} from "react-router-dom";



//componentes
import Footer from '../footer';
import './fotos.css';

class Fotos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            botonOn:true,
            mensajealerta:false,
        }
       
      
    }

    onChange(e) {
        //console.log(e.target.name)
        this.setState({
            [e.target.name]: e.target.value
             
        })
    }

    handleClose = () => {
        this.setState({ mensajealerta: false })
    }


    render(){
     
        return(
            <div className="container">
                <div className="cont-foto-principal row">
                    <div className="cont-foto col-12">
                        <div className="eslogan">
                            <p>Fotos</p>
                        </div>
                    </div>
                </div>
                <div className="cont-foto-principal row"> 
                    <div className="card-group col-12" id="card-group1">
                        <div className="card" id="cardp" >
                            <img className="card-img-top" id="xcard1f" src="/fotos/foto1.jpeg" alt="Card image cap"/>
                        </div>
                        <div className="card">
                            <img className="card-img-top" id="xcard1f" src="/fotos/foto2.jpeg" alt="Card image cap"/>
                        </div>
                        <div className="card ">
                            <img className="card-img-top" id="xcard1f" src="/fotos/foto3.jpeg" alt="Card image cap"/>
                        </div>
                    </div>
                </div>
                <div className="cont-foto-principal row"> 
                    <div className="card-group col-12" id="card-group2" >
                        <div className="card" id="cardp" >
                            <img className="card-img-top" id="xcard1f" src="/fotos/foto4.jpeg" alt="Card image cap"/>
                        </div>
                        <div className="card">
                            <img className="card-img-top" id="xcard1f" src="/fotos/foto5.jpeg" alt="Card image cap"/>
                        </div>
                        <div className="card">
                            <img className="card-img-top" id="xcard1f" src="/fotos/foto6.jpeg" alt="Card image cap"/>
                        </div>
                    </div>  
                </div>
                <div className="cont-foto-principal row"> 
                    <div className="card-group col-12" id="card-group4" >
                        <div className="card" id="cardp" >
                            <img className="card-img-top" id="xcard1f" src="/fotos/foto10.jpeg" alt="Card image cap"/>
                        </div>
                        <div className="card">
                            <img className="card-img-top" id="xcard1f" src="/fotos/foto11.jpeg" alt="Card image cap"/>
                        </div>
                        <div className="card">
                            <img className="card-img-top" id="xcard1f" src="/fotos/foto12.jpeg" alt="Card image cap"/>
                        </div>
                    </div>    
                </div>
                <div className="cont-foto-principal row"> 
                    <div className="card-group col-12" id="card-group5" >
                        <div className="card" id="cardp" >
                            <img className="card-img-top" id="xcard1f" src="/fotos/foto13.jpeg" alt="Card image cap"/>
                        </div>
                        <div className="card">
                            <img className="card-img-top" id="xcard1f" src="/fotos/foto14.jpeg" alt="Card image cap"/>
                        </div>
                        <div className="card">
                            <img className="card-img-top" id="xcard1f" src="/fotos/foto15.jpeg" alt="Card image cap"/>
                        </div>
                    </div>
                </div>  
                <div className="cont-foto-principal row"> 
                    <div className="card-group col-12" id="card-group6" >
                        <div className="card" id="cardp" >
                            <img className="card-img-top" id="xcard1f" src="/fotos/foto16.jpeg" alt="Card image cap"/>
                        </div>
                        <div className="card">
                            <img className="card-img-top" id="xcard1f" src="/fotos/foto17.jpeg" alt="Card image cap"/>
                        </div>
                        <div className="card">
                            <img className="card-img-top" id="xcard1f" src="/fotos/foto18.jpeg" alt="Card image cap"/>
                        </div>
                    </div>
                </div>
                <div className="cont-foto-principal row"> 
                    <div className="card-group col-12" id="card-group7" >
                        <div className="card" id="cardp" >
                            <img className="card-img-top" id="xcard1f" src="/fotos/foto19.jpeg" alt="Card image cap"/>
                        </div>
                        <div className="card">
                            <img className="card-img-top" id="xcard1f" src="/fotos/foto20.jpeg" alt="Card image cap"/>
                        </div>
                        <div className="card">
                            <img className="card-img-top" id="xcard1f" src="/fotos/foto21.jpeg" alt="Card image cap"/>
                        </div>
                    </div>                                                                            
                </div>
            </div>
            
        )
    }
}export default Fotos;    