import React, { Component } from 'react';
//import axios from 'axios';
import {Link} from "react-router-dom";



//componentes
import Footer from '../footer';
import './superior.css';

class Superior extends Component {
    constructor(props) {
        super(props);
        this.state = {
            botonOn:true,
            mensajealerta:false,
        }
       
      
    }

    onChange(e) {
        //console.log(e.target.name)
        this.setState({
            [e.target.name]: e.target.value
             
        })
    }

    handleClose = () => {
        this.setState({ mensajealerta: false })
    }


    render(){
     
        return(
            <div className="container-fluid">
                <div className="cont-servi-principal row">
                    <div className="cont-imagen1su col-7">
                        <div className="imagen1su">
                            <img className="card-img-top" id="img1su" src="/imgsuperior/foto1.jpg" alt="Card image cap"/>
                        </div>
                        <div className="imagen2su">
                            <img className="card-img-top" id="img2su" src="/imgsuperior/foto2.jpg" alt="Card image cap"/>
                        </div>
                        {/* <div className="imagen3pre">
                            <img className="card-img-top" id="img3pre" src="/imgpremedia/foto3.jpg" alt="Card image cap"/>
                        </div> */}
                        
                    </div>
                    <div className="eslogan1su col-4">
                        <p className="titulosu">Superior</p>
                        <div className="cuerpo-textosu">
                            <p className="cuerpotexto1su">Una vez sean aprobadas tendremos la apertura de carreras a nivel superior, con la finalidad de darle continuidad a nuestros estudiantes.</p>
                            <p className="cuerpotexto2pre">Las carreras que estaremos ofreciendo a los estudiantes son:</p>
                            

                            


                        {/*  <p className="cuerpotexto3pre"><b id="cuerpotexto3pre">Salón máximo de 20 alumnos.</b></p>
                            <p className="cuerpotexto4pre"><b id="cuerpotexto4pre">Horario de 7:30am a 2:00pm.</b></p>
                            <p className="cuerpotexto5pre"><b id="cuerpotexto5pre">Clases pedagócicas.</b></p>
                            <p className="cuerpotexto6pre"><b id="cuerpotexto6pre">Clases de Inglés todos los días.</b></p>*/}
                            <p className="cuerpotexto7pre"><b id="cuerpotexto7pre">•TÉCNICO EN LOGÌSTICA INTEGRAL.</b></p>
                            <p className="cuerpotexto8pre"><b id="cuerpotexto8pre">•ADMINISTRACIÓN ESCOLAR CON ÉNFASIS EN DIRECCIÓN Y SUPERVISIÓN ESCOLAR.</b></p>
                            <p className="cuerpotexto9pre"><b id="cuerpotexto9pre">•ADMINISTRACIÓN DE NUEVAS TECNOLOGÍAS EN EL CAMPO DE LA EDUCACIÓN.</b></p> 
                        </div>
                    </div>
                </div>
            </div>
            
        )
    }
}export default Superior;    