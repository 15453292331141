import React, { Component } from 'react';
//import axios from 'axios';
import {Link} from "react-router-dom";



//componentes
import Footer from '../footer';
import './planillamc.css';

class PlanillaMC extends Component {
    constructor(props) {
        super(props);
        this.state = {
            botonOn:true,
            mensajealerta:false,
        }
       
      
    }

    onChange(e) {
        //console.log(e.target.name)
        this.setState({
            [e.target.name]: e.target.value
             
        })
    }

    handleClose = () => {
        this.setState({ mensajealerta: false })
    }


    render(){
     
        return(
            <div className="cont-servi-principal row">
                <div className="cont-servi col-12">
                    <div className="container row">
                        <div className="eslogan1mc col-4">
                            <p className="titulomc">EN CONSTRUCCION</p>
                        </div>
                    </div>
                                       
                    {/* <Footer />  */}
                </div>        
                        
            </div>
  
            
        )
    }
}export default PlanillaMC;    