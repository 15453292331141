import React, { Component } from 'react';
//import axios from 'axios';
import {Link} from "react-router-dom";



//componentes
import Footer from '../footer';
import './nosotros.css';

class Nosotros extends Component {
    constructor(props) {
        super(props);
        this.state = {
            botonOn:true,
            mensajealerta:false,
        }
       
      
    }

    onChange(e) {
        //console.log(e.target.name)
        this.setState({
            [e.target.name]: e.target.value
             
        })
    }

    handleClose = () => {
        this.setState({ mensajealerta: false })
    }


    render(){
     
        return(
            <div className="container">
                <div className="cont-nosotros-principal row">
                    <div className="cont-nosotros col-12">
                        <div className="eslogan">
                            <p>Nosotros</p>
                        </div>
                    </div>
                </div>    
                <div className="cont-nosotros-principal row">
                    <div className="mision col-12">
                        <p className="tn"><b>Colegio Bilingüe Las Naciones</b> nace del deseo de ofrecer una educación de calidad accesible para todos. Nuestra misión es sembrar valores, tales como: el respeto, compromiso, responsabilidad y sobre todo la honestidad en nuestros estudiantes a través de los principios cristianos, proveyéndoles todas las herramientas para su desarrollo y crecimiento para su futuro. </p>
                        <p className="tn">El nombre <b>“Las Naciones”</b> resalta la diversidad de culturas que abarca nuestro cuerpo estudiantil. Además de eso, somos un instituto inclusivo. Ser diferente por una condición no compromete ni perjudica la oportunidad de ser parte de nuestra familia. Ningún niño debería ser subestimado por cualquier tipo de condición que padezca. Ser diferente, no te hace menos. Te hace más especial.</p>
                        <p className="tn">Nuestra prioridad siempre será el bienestar de nuestros estudiantes, por ello creamos un ambiente que no solo despierta sus ansias por aprender sino que también transmite la confianza y amor de una familia. Nuestro objetivo es que el <b>Colegio Bilingüe Las Naciones</b> sea más que la insignia en un uniforme, que lo lleven consigo después de cruzar la puerta y sigan enseñando el buen camino con los que lo rodean.</p>
                    </div>                   
                </div>
                <div className="row">
                    <div className="mision col-12">
                        <p className="tmision"><b>MISIÓN</b></p>
                        <p className="tn">Brindar todo el apoyo posible para que cada alumno logre el máximo desarrollo de sus capacidades. </p>
                        <p className="tvision"><b>VISIÓN</b></p>
                        <p className="tn">Ser reconocidos como un centro de educación integral que ha contribuido a que sus alumnos logren una excelente educación para toda la vida. </p>
                        <p className="tvalores"><b>VALORES</b></p>
                        <p className="tn1">o	Respeto. </p>
                        <p className="tn1">o	Compromiso. </p>
                        <p className="tn1">o	Responsabilidad. </p>
                        <p className="tn1">o	Honestidad. </p>
                    </div>          
                </div>
            </div>
            
        )
    }
}export default Nosotros;    