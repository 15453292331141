import React, { Component } from 'react';
//import axios from 'axios';
import {Link} from "react-router-dom";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";


//componentes
//import TwitterIcon from '@material-ui/icons/Twitter';
//import YouTubeIcon from '@material-ui/icons/YouTube';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import Icon from '@material-ui/core/Icon';
import './footer.css';
import logo1 from '../../imagenes/Logo.png'
//import img2 from '../../imagenes/Flechad.gif'
//import Alert from '../../modal/alert';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cancion: '',
            botonOn:true,
            mensajealerta:false,
            codigo:'',
            tcaso:'',
            guardarOn:false,
            isOpen: false
        }
    }

    onChange(e) {
        //console.log(e.target.name)
        this.setState({
            [e.target.name]: e.target.value
             
        })
    }

    handleClose = () => {
        this.setState({ mensajealerta: false })
    }

    componentDidMount(){
        
    }
    toggleCollapse = () => {
        this.setState({ isOpen: !this.state.isOpen });
      }

    render(){
        
        return(
            <div className="container-fluid"> 
                <div className="contfooter row">
                    
                    <div className="logo col-3">               
                        <a className="navbar-brand"> <Link to="/"><img className="logof" src={logo1} /></Link> </a>
                    </div>
                    <div className="contacto col-3">
                        <p className="contac">Contáctenos</p>
                        <p className="datos">Calle 41 Este, Edificio IPASA</p>
                        <p className="datos">a 50 mts del DGI - Panama</p>
                        <p className="datos">+507 831-7601 / +507 831-7602</p>
                        <p className="datos">+507 6330 9078</p>
                    </div>
                    <div className="contacto col-3">
                        <p className="contac">Explora CBLN</p>
                        <p className="datos">Maternal</p>
                        <p className="datos">Preescolar</p>
                        <p className="datos">Primaria</p>
                        <p className="datos">Premedia</p>
                        <p className="datos">Superior</p>
                    </div>

                    <div className="contacto col-3">
                        <p className="contac">Síguenos</p>
                        <div className="socialesf">
                            <a href="https://instagram.com/colegiobilinguelasnaciones?igshid=1sg8v3kxnc0r5" target="__blank"><InstagramIcon style={{ fontSize: 40, marginRight:30, color:'fff' }}/></a>
                            <a href="https://www.facebook.com/278054962801682" target="__blank"><FacebookIcon style={{ fontSize: 40, color:'fff'}}/></a>
                        </div>    

                    </div>
                    
                </div>
            </div>


        )
    }
}export default Footer;    